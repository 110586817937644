import React from 'react'
import MiHistorial from '../../views/Historial'
import ProfileSideBar from './ProfileSideBar'

export default function Historial({ location }) {
    return (
        <ProfileSideBar location={location}>
            <MiHistorial />
        </ProfileSideBar>
    )
}
