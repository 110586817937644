import React, { useEffect, useState } from "react";
import "./CardSolicitud.css";
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  makeStyles,
  Container,
} from "@material-ui/core";
import Ojito from "../../Assets/Ojito.png";
import moment from "moment";
import API from "../../API";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  card: {
    position: "relative",
    width: "260px",
    height: "auto",
    minHeight: "540px",
    paddingBottom: "15px",
    backgroundColor: "#FFEBE6",
    backgroundImage: "url(" + Ojito + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    marginLeft: "40px",
    marginBottom: "30px",
    borderRadius: "25px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  card2: {
    position: "relative",
    width: "260px",
    height: "auto",
    paddingBottom: "15px",
    backgroundColor: "#FF952D",
    backgroundImage: "url(" + Ojito + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    marginLeft: "40px",
    marginBottom: "30px",
    borderRadius: "25px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  btnAceptar: {
    width: "60%",
    backgroundColor: "#A52F43",
    color: "white",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    letterSpacing: "2px",
  },
  btnDeclinar: {
    width: "60%",
    marginTop: 15,
    color: "#A52F43",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    borderWidth: "1.7px",
    borderColor: "#A52F43",
  },
  cardExpress: {
    borderRadius: "20px",
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    padding: "10px",
  },
  cardRegular: {
    borderRadius: "20px",
    backgroundColor: "orange",
    color: "white",
    textAlign: "center",
    padding: "10px",
  },
}));

export default function CardSolicitud(props) {
  console.log(props.card);

  // /available-services/3/ Obtener un servicio
  // /user-details/2/ Obtener userinfo

  const [servicesInfo, setServicesInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});

  // useEffect(() => {
  //   API.get(`/available-services/${props.card.availableService}/`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('access')}`
  //     }
  //   })
  //     .then(res => {
  //       // console.log(res.data)
  //       setServicesInfo(res.data)
  //     })

  // }, [])

  // useEffect(() => {
  //   API.get(`/user/${props.card.client}/`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('access')}`
  //     }
  //   })
  //     .then(res => {
  //       console.log(res.data)
  //       setUserInfo(res.data)
  //     })

  // }, [])

  // const start = moment(props.card.event.start)
  // const end = moment(props.card.event.end)
  // const diff = end.diff(start)
  // const diffMinutes = moment.duration(diff)

  const classes = useStyles();

  const updateToFinished = (id) => {
    API.put(
      `/v1/appointmnets/${id}/`,
      {
        appointment_status: 4,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    ).then((res) => {
      props.removeCard(props.id, props.card.idx);
    });
  };

  //Status = 1 *Pendiente de aceptar - Punto de inicio.
  //Status = 5 *Cuando acepto la cita.
  function renderTypeBtn(status, date_appointment, start_of_appointment) {
    const currentHour = new Date().getHours();

    if (status === 1) {
      return (
        <Button
          onClick={() => props.removeCard(props.card.id, 5)}
          className={classes.btnAceptar}
          variant="contained"
          disableElevation
        >
          ACEPTAR
        </Button>
      );
    }

    if (status === 5) {
      return (
        <Button
          onClick={() => props.removeCard(props.card.id, 3)}
          className={classes.btnAceptar}
          variant="contained"
          disableElevation
        >
          CANCELAR
        </Button>
      );
    }

    const startAppointmentToInt = parseInt(start_of_appointment.split(":"));
    if (status === 5 && currentHour > startAppointmentToInt) {
      return (
        <Button
          onClick={() => updateToFinished(props.card.id)}
          className={classes.btnAceptar}
          variant="contained"
          disableElevation
        >
          FINALIZAR
        </Button>
      );
    }

    return null;
  }

  return (
    <Card elevation={0} className={classes.card}>
      <div
        className={
          props.card.type_appointment === 1
            ? classes.cardRegular
            : classes.cardExpress
        }
      >
        {props.card.type_appointment === 1 ? "Regular" : "Express"}
      </div>
      <Container
        style={{
          minHeight: 480,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <CardContent style={{ marginTop: "20px" }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: "#E62C4C",
              fontFamily: "Montserrat",
            }}
          >
            {/* {servicesInfo && <>{servicesInfo.name}</>} */}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>
              Nombre: {props.card.client.first_name}{" "}
            </span>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>Pago: </span> $
            {Number(
              Number(props.card.sub_total) -
                Number(props.card.sub_total) * Number(props.comision)
            ).toFixed(2)}{" "}
            MXN
          </Typography>

          {props.card.coupon && (
            <Typography
              variant="subtitle1"
              style={{ marginTop: "5px", fontFamily: "Montserrat" }}
            >
              <span style={{ fontWeight: "bold" }}>Cupon usado: </span>
              {props.card.coupon.coupon} - {props.card.coupon.description}
            </Typography>
          )}

          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>Fecha: </span>{" "}
            {moment(props.card.date_appointment).format("DD/MM/YY")}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>Inicio: </span>{" "}
            {props.card && <>{props.card.start_of_appointment}</>}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>Fin: </span>{" "}
            {props.card && <>{props.card.end_of_appointment}</>}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>Servicio: </span>{" "}
            {props?.card?.service_by_collaborator && (
              <>{props.card.service_by_collaborator.service_name}</>
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "5px", fontFamily: "Montserrat" }}
          >
            <span style={{ fontWeight: "bold" }}>Direccíon: </span>{" "}
            {props.card && <>{props.card.reference}</>}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          {renderTypeBtn(
            props.card.appointment_status,
            props.card.date_appointment,
            props.card.start_of_appointment
          )}
          {props.card.appointment_status === 1 && (
            <Button
              onClick={() => props.removeCard(props.card.id, 3)}
              className={classes.btnDeclinar}
              style={{ margin: 0, marginTop: 20 }}
              variant="outlined"
            >
              DECLINAR
            </Button>
          )}
          {props.card.appointment_status === 3 && (
            <div
              className={classes.btnDeclinar}
              style={{ margin: 0, marginTop: 20 }}
              variant="outlined"
            >
              CANCELADA
            </div>
          )}
          {props.card.appointment_status === 4 && (
            <div
              className={classes.btnDeclinar}
              style={{ margin: 0, marginTop: 20 }}
              variant="outlined"
            >
              CONCLUIDA
            </div>
          )}
        </CardActions>
      </Container>
    </Card>
  );
}
