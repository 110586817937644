import React, { useState, useEffect } from 'react'
import ProfileSideBar from './ProfileSideBar'
import CardSolicitud from '../../UI/CardSolicitud/CardSolicitud'
import { Container } from '@material-ui/core'
import API from '../../API'
import JwtDecode from 'jwt-decode'
import moment from 'moment'

export default function Solicitud({ location }) {

    const [cards, setCards] = useState([])
    const [comision, setComision] = useState(0)
    const getItems= () =>{
        const decoded = JwtDecode(localStorage.getItem('access'))

        API.get(`/v1/appointment/?appointment_status=5&collaborador=${decoded.user_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                console.log(res.data)
               // setCards(res.data.data)
               checkPast(res.data.data
                .filter(cita => moment(cita.date_appointment).toDate().getTime()
                    < moment(new Date()).subtract(1, 'day').toDate().getTime() && cita.appointment_status === 5
                ))
                setCards(res.data.data.filter(cita => moment(cita.date_appointment).toDate().getTime() > moment(new Date()).subtract(1, 'day').toDate().getTime()))

            })
    }
    useEffect(() => {
        const decoded = JwtDecode(localStorage.getItem('access'))
        API.get(`/v1/membership/`)
        .then(res => {
            //console.log("DATA",res.data)
            API.get(`/user/${decoded.user_id}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`
                }
            })
                .then(res2 => {
                    console.log(res2.data)
                    if (res2.data.collaborator_suscription.length && res2.data.collaborator_suscription.length>0) {
                        setComision(res2.data.collaborator_suscription[0].membership.comision)
                    }
                })
        })
        .catch(error => console.log(error))

        getItems()
    }, [])


        const removeCard = (id, status) => {
            API.put(`/v1/appointmnets/${id}/`, {
                appointment_status: status
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`
                }
            }).then((v) => {
                console.log("SUCCESS", v)
                getItems()
            }).catch(e => {
                console.log("ERROR", e)
                getItems()
             
            })
        }
     

    const checkPast = (list = []) => {
        if (list && list.length > 0)
            list.map(cita => {
                API.put(`/v1/appointmnets/${cita.id}/`, {
                    appointment_status: 4
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access')}`
                    }
                }).then((v) => {
                    console.log("SUCCESS")
                }).catch(e => {
                    console.log("ERROR", e)
                })
            })

    }

    return (
        <ProfileSideBar location={location}>
            <Container>
                <p style={{ fontFamily: 'Montserrat', fontSize: '18px', marginTop: '20px' }}>
                    Éstas son las citas que ya han sido aceptadas y están próximas para que se realice un servicio.
                    Te recomendamos estar muy pendiente para que no faltes a ninguna.
                    ¡Mucho éxito!
                </p>
                <p style={{ fontFamily: 'Montserrat', fontSize: '18px', marginTop: '10px', color: 'red' }}>
                    Te recordamos que para hacer una cancelación tiene que hacerse con 36 horas de anticipación.
                </p>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: '30px' }}>
                    {cards.length > 0 ?
                        cards.map((card, idx) => (
                            <CardSolicitud comision={comision}card={card} idx={idx} removeCard={removeCard} />
                        ))
                        :
                        'No hay citas por realizar'
                    }
                </div>
            </Container>
        </ProfileSideBar>
    )
}
