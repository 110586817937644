import React from 'react'
import ProfileSideBar from './ProfileSideBar'
import MiPerfil from './MiPerfil'

export default function Perfil({location}) {

    return (
        <ProfileSideBar location={location}>
            <MiPerfil />
        </ProfileSideBar>
    )
}
