import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { FaBars } from "react-icons/fa";
import Logo from "../../Assets/hg_logo_large.svg";
import "./Navbar.css";
import JwtDecode from "jwt-decode";

export default function Navbar() {
  const decoded = JwtDecode(localStorage.getItem("access"));

  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);

  function handleLogOut() {
    sessionStorage.clear();
    localStorage.clear();
    history.go(0);
  }

  return (
    <>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className="nosotrosContainer">
          {decoded.type === 2 && (
            <div onClick={() => history.push("/")}>Inicio</div>
          )}
          <div onClick={() => history.push("/contenido")}>Portafolio</div>
          <div onClick={() => history.push("/membresia")}>Membresías</div>
          {decoded.type === 2 && (
            <div onClick={() => history.push("/agenda")}>Agenda</div>
          )}
          <div onClick={() => history.push("/perfil")}>Administrar</div>
          <div onClick={handleLogOut}>Cerrar Sesión</div>
        </div>
      </Drawer>

      <nav className="main">
        <div className="mobileContent">
          <span
            onClick={() => setDrawerOpen(true)}
            style={{ fontSize: "2em", cursor: "pointer" }}
          >
            <FaBars />
          </span>
          <img
            onClick={() => history.push("/")}
            src={Logo}
            alt="HolyGlamLogo"
          />
        </div>
        <div className="content">
          <div style={{ cursor: "pointer" }} onClick={() => history.push("/")}>
            <img src={Logo} alt="HolyGlamLogo" />
          </div>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          {decoded.type === 2 && (
            <div
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            >
              Inicio
            </div>
          )}
          <div
            onClick={() => history.push("/contenido")}
            style={{ cursor: "pointer" }}
          >
            <span>Portafolio</span>
          </div>
          {/* <div 
                        onClick={() => history.push('/membresia')}
                        style={{cursor:'pointer'}}>
                        <span>Membresias</span>
                    </div> */}
          {decoded.type === 2 && (
            <div
              onClick={() => history.push("/agenda")}
              style={{ cursor: "pointer" }}
            >
              Agenda
            </div>
          )}
          <div
            onClick={() => history.push("/perfil")}
            style={{ cursor: "pointer" }}
          >
            Administrar
          </div>
          <div onClick={handleLogOut} style={{ cursor: "pointer" }}>
            Cerrar Sesión
          </div>
        </div>
      </nav>
    </>
  );
}
