import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import API from "../../API";
import JwtDecode from "jwt-decode";
import { useStripe } from "@stripe/react-stripe-js";
import Modal from "../../UI/Modal/Modal";
import Loader from "../../UI/FullScreenLoader/FullScreenLoader";
import Button from "../../UI/Button/Button";

export default function CardMembresia(props) {
  const classes = useStyles();
  const { memembresia } = props;
  const [customerId, setCustomerId] = useState("");
  const [priceId, setPriceId] = useState(memembresia.stripe_price_id);
  const [idMembershipType, setIdMembershipType] = useState(props.id);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cards, setCards] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isSubDone, setIsSubDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suscriptionActive, setSuscriptionActive] = useState(undefined);

  const stripe = useStripe();

  useEffect(() => {
    setLoading(true);

    const decoded = JwtDecode(localStorage.getItem("access"));

    API.get(`/v1/stripe-payment-method/?user=${decoded.user_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    }).then((res) => {
      setCards(res.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    API.get(`/user/${decoded.user_id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    }).then((res) => {
      console.log(res.data);
      setCustomerId(res.data.id);
      if (res.data.collaborator_suscription.length) {
        setSuscriptionActive(res.data.collaborator_suscription[0]);
      }
    });
  }, []);

  const createSubscription = () => {
    console.log(customerId);
    console.log(priceId);
    console.log(paymentMethod);

    let formdata = new FormData();

    formdata.append("paymentMethodId", paymentMethod);
    formdata.append("customerId", customerId);
    formdata.append("priceId", priceId);

    API.post("/create-subscription", formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        return res.data;
      })
      .then((result) => {
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then((result) => {
        return {
          paymentMethodId: paymentMethod,
          priceId: priceId,
          subscription: result,
        };
        // console.log(result)
      })
      .then(handlePaymentThatRequiresCustomerAction)
      .catch((error) => console.log(error));
  };

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice = false,
    priceId,
    paymentMethodId,
    isRetry = false,
  }) => {
    console.log(subscription, "subscription");
    console.log(invoice, "invoice");
    console.log(priceId, "priceId");
    console.log(paymentMethodId, "paymentid");
    console.log(isRetry, "isRetry");

    // debugger
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }
    // return console.log(subscription)
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              console.log(result);
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  };

  const updateSubscription = () => {
    let formdata = new FormData();

    formdata.append("paymentMethodId", paymentMethod);
    formdata.append("customerId", customerId);
    formdata.append("priceId", priceId);

    API.post("/create-subscription", formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
  };

  const createMembership = (start, end, stripe_product) => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    let formdata = new FormData();

    formdata.append("catMembership", memembresia.id);
    formdata.append("start", start);
    formdata.append("end", end);
    formdata.append("stripe_product_id", stripe_product);
    formdata.append("user", decoded.user_id);
    formdata.append("is_active", true);

    API.post("/memberships/", formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  };

  function createSubscriptionV2() {
    setLoading(true);
    console.log(props.id);

    let data = new FormData();
    data.append("customer_id", customerId);
    data.append("payment_method", paymentMethod);
    data.append("membership_id", memembresia.id);

    API.post(`/v1/subscription/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function updateSuscriptionV2Trial() {
    setLoading(true);

    let data = new FormData();
    data.append("subscription_id", suscriptionActive.id);
    data.append("membership_id", memembresia.id);
    data.append("payment_method", paymentMethod);

    API.post(`/v1/update-subscription/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function updateSuscriptionV2() {
    setLoading(true);

    let data = new FormData();
    data.append("subscription_id", suscriptionActive.id);
    data.append("membership_id", memembresia.id);

    API.post(`/v1/update-subscription/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function cancelSuscriptionV2() {
    setLoading(true);

    let data = new FormData();
    data.append("subscription_id", suscriptionActive.id);
    data.append("colaborator_id", customerId);

    API.post(`/v1/cancel-subscription`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function buildBtn() {
    if (
      suscriptionActive &&
      suscriptionActive.membership.id === memembresia.id &&
      !suscriptionActive.is_cancelled
    ) {
      return <Button onClick={cancelSuscriptionV2}>CANCELAR</Button>;
    }
    if (
      suscriptionActive &&
      !suscriptionActive.is_cancelled &&
      suscriptionActive.stripeSubscriptionId
    ) {
      return <Button onClick={updateSuscriptionV2}>ACTUALIZAR</Button>;
    }
    if (
      suscriptionActive &&
      !suscriptionActive.is_cancelled &&
      !suscriptionActive.stripeSubscriptionId
    ) {
      return <Button onClick={() => setVisible(true)}>ACTUALIZAR</Button>;
    }
    return <Button onClick={() => setVisible(true)}>ACEPTAR</Button>;
  }

  return (
    <>
      <Loader visible={loading} />
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <h2 style={{ marginBottom: "50px" }}>Seleccione una forma de pago</h2>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={paymentMethod}
              onChange={(e) => {
                setPaymentMethod(e.target.value);
                console.log(e.target.value);
              }}
            >
              {cards.length ? (
                cards.map((card, idx) => (
                  <FormControlLabel
                    key={idx}
                    style={
                      paymentMethod === card.id.toString()
                        ? {
                            backgroundColor: "#E62C4C",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "10px",
                          }
                        : {}
                    }
                    value={card.id.toString()}
                    control={<Radio />}
                    label={`${card.name_card} - **** **** ****${card.last4} Ven: (${card.exp_month} / ${card.exp_year})`}
                  />
                ))
              ) : (
                <FormControlLabel
                  value="disabled"
                  disabled
                  control={<Radio />}
                  label="Sin métodos de pago"
                />
              )}
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className={classes.btnAceptar}
            onClick={() => {
              if (
                suscriptionActive &&
                !suscriptionActive.is_cancelled &&
                !suscriptionActive.stripeSubscriptionId
              ) {
                updateSuscriptionV2Trial();
              } else createSubscriptionV2();
            }}
            variant="contained"
            disabled={paymentMethod.length ? false : true}
          >
            Aceptar
          </Button>
        </div>
        {/* {isSubDone && <h2 style={{ textAlign: 'center' }}>Suscripción Realizada con exito</h2>} */}
      </Modal>
      {/* <Grid className={classes.grid2} >
                <Grid item xs={12} sm={6} style={{ alignSelf: 'center' }} >
                    <Typography className={classes.titleCard} variant='subtitle1'>{memembresia.description}</Typography>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        {buildBtn()}
                    </div>
                    {suscriptionActive && suscriptionActive.membership === memembresia.id
                        && !suscriptionActive.is_cancelled &&
                        <div className={classes.textCenter}>
                            <h1>ACTIVA</h1>
                        </div>
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img className={classes.imgCard} alt='membresia' src={memembresia.image} />
                    </div>
                </Grid>
            </Grid> */}

      <div className={classes.card}>
        <div className={classes.container}>
          {/*  <span className={classes.banner}>1er mes gratis al suscribirse</span> */}
          {!memembresia.is_free ? (
            <span className={classes.banner}>{memembresia.free_text}</span>
          ) : (
            <br/>
          )}
          <div className={classes.cardHeader}>
            <h2>{memembresia.name}</h2>
          </div>
          <span>{memembresia.description}</span>
          <div className={classes.divider} />
          <ul className={classes.ul}>
            {memembresia.characteristics.map((res, key) => {
              return <li key={`${res.id}-${key}`}>{res}</li>;
            })}
          </ul>
        </div>
        <div className={classes.btnAlign}>{buildBtn()}</div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  grid2: {
    display: "flex",
    position: "relative",
    backgroundColor: "#FFD9CB",
    alignContent: "center",
    marginLeft: "30px",
    marginTop: "20px",
    height: "300px",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      marginLeft: 0,
    },
  },
  titleOption: {
    fontFamily: "bb-book-mono",
    color: "#E62C4C",
    fontWeight: "bold",
    marginTop: "10%",
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px",
    },
  },
  imgCard: {
    position: "relative",
    height: "350px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  titleCard: {
    fontFamily: "bb-book-mono",
    color: "#E62C4C",
    fontWeight: "bold",
    lineHeight: "31px",
    letterSpacing: "1.28px",
  },
  btnAceptar: {
    width: "50%",
    backgroundColor: "#A52F43",
    color: "white",
    fontFamily: "bb-book-mono",
    fontWeight: "bold",
    letterSpacing: "2px",
  },
  textCenter: {
    textAlign: "center",
    color: "#A52F43",
  },
  card: {
    width: "280px",
    border: "2px solid rgba(0,0,0,0.4)",
    borderRadius: "10px",
    margin: "20px",
    minHeight: 750,
  },
  banner: {
    color: "white",
    backgroundColor: "#A52F43",
    padding: "6px",
    fontFamily: "Montserrat",
    fontSize: 14,
    borderRadius: 4,
  },
  divider: {
    margin: "25px 0px",
    backgroundColor: "black",
    width: "100%",
    height: 1.8,
  },
  container: {
    margin: "20px",
    minHeight: 474,
  },
  btnAlign: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      borderRadius: 15,
      backgroundColor: "#E62C4C",
    },
  },
  ul: {
    paddingInlineStart: "16px !important",
    "& li": {
      paddingBottom: 20,
    },
  },
}));
