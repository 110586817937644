import axios from 'axios'

const API = axios.create({
    baseURL: 'https://api.holyglam.com.mx'
  //baseURL:'http://c7cb3a025a12.ngrok.io'
  // baseURL: 'https://api.test.holyglam.com.mx'
  //baseURL: 'https://c793-2806-2f0-83e0-40dd-6917-57ea-638e-c450.ngrok.io'
})
/* Comentario de pruebas */
API.interceptors.response.use(response => {
    return response
}, error => {
    // if (error.response.status === 401) {
    //     sessionStorage.clear()
    //     localStorage.clear()
    //     window.location = '/'
    // }

    return Promise.reject(error);
})

export default API