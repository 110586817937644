import React from "react";
import { useHistory } from "react-router-dom";
import classes from "../Perfil/Perfil.module.css";
import jwtDecode from "jwt-decode";

export default function ProfileSideBar({ location, children }) {
  const decoded = jwtDecode(localStorage.getItem("access"));

  const history = useHistory();

  function handleLogOut() {
    sessionStorage.clear();
    localStorage.clear();
    history.go(0);
  }

  return (
    <section className={classes.main}>
      <div className={classes.sidebar}>
        <div
          onClick={() => history.push("/perfil")}
          style={
            location.pathname === "/perfil"
              ? { color: "#E62C4C", fontWeight: "700" }
              : null
          }
        >
          Mi perfil
        </div>
        {decoded.type === 2 && (
          <>
            <div
              onClick={() => history.push("/perfil/solicitud")}
              style={
                location.pathname === "/perfil/solicitud"
                  ? { color: "#E62C4C", fontWeight: "700" }
                  : null
              }
            >
              Citas
            </div>
            <div
              onClick={() => history.push("/perfil/MetodosPago")}
              style={
                location.pathname === "/perfil/MetodosPago"
                  ? { color: "#E62C4C", fontWeight: "700" }
                  : null
              }
            >
              Métodos de Pago
            </div>
            <div
              onClick={() => history.push("/perfil/membresias")}
              style={
                location.pathname === "/perfil/membresias"
                  ? { color: "#E62C4C", fontWeight: "700" }
                  : null
              }
            >
              Membresías
            </div>
            <div
              onClick={() => history.push("/perfil/guardia")}
              style={
                location.pathname === "/perfil/guardia"
                  ? { color: "#E62C4C", fontWeight: "700" }
                  : null
              }
            >
              Express
            </div>
            <div
              onClick={() => history.push("/perfil/historial")}
              style={
                location.pathname === "/perfil/historial"
                  ? { color: "#E62C4C", fontWeight: "700" }
                  : null
              }
            >
              Historial
            </div>
          </>
        )}
        {/* <div
                        style={{color:'#E62C4C', fontWeight:'bold'}}
                        onClick={handleLogOut}
                    >
                        Cerrar Sesión
                    </div> */}
      </div>
      <div className={classes.content}>{children}</div>
    </section>
  );
}
