import React, { useEffect, useState } from 'react'
import './Home.css'
import { Grid, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CardSolicitud from '../UI/CardSolicitud/CardSolicitud';
import API from '../API';
import JwtDecode from 'jwt-decode'
import moment from 'moment'
import SnackBar from '../UI/SnackBar/SnackBar';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        // height: '70vh'
        marginBottom: '18%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    grid: {
        display: 'flex',
        height: '100%',
        alignContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    card: {
        position: 'relative',
        width: '30%',
        height: '300px',
        backgroundColor: '#ccc',
        marginLeft: '40px'
    },
    imgHero: {
        position: 'absolute',
        marginLeft: '20px',
        right: '60px',
        height: '450px',
        bottom: '-15px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    welcomeText: {
        fontFamily: 'bb-book-mono',
        color: '#fff',
        fontWeight: 'bold',
        marginBottom: 45,
        letterSpacing: 3,
        [theme.breakpoints.only('xs')]: {
            fontSize: '30px'
        },
    },
    subtitleText: {
        fontFamily: 'bb-book-mono',
        color: '#fff',
        [theme.breakpoints.only('xs')]: {
            fontSize: '20px'
        },
    },
    TitleSolicitud: {
        fontFamily: 'Montserrat',
        color: '#A52F43',
        letterSpacing: 3,
        // marginLeft: '105px',
        marginTop: '3%',
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            fontSize: '25px'
        },
    },
    img: {
        transform: "translate(-8px, 30px)"
    }
}));

export default function Home() {
const history = useHistory()
    const classes = useStyles();
    const [cards, setCards] = useState([])
    const [comision, setComision] = useState(0)
    const [msgSnack, setMsgSnack] = useState('')
    const [openSnack, setOpenSnack] = useState(false)

    const decoded = JwtDecode(localStorage.getItem('access'))
    const getItems = () => {
        API.get(`/v1/membership/`)
            .then(res => {
                //console.log("DATA",res.data)
                API.get(`/user/${decoded.user_id}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access')}`
                    }
                })
                    .then(res2 => {
                        const subscription =res2.data.collaborator_suscription
                        if (decoded.type===4) return history.push('/perfil')
                        if(subscription.length===0) return history.push('/perfil/membresias')
                        if(subscription.length>0 && !subscription[0].is_paid)return history.push('/perfil/membresias')
                        if (subscription.length && subscription.length > 0) {
                            return setComision(subscription[0].membership.comision)
                        }
                    })
            })
            .catch(error => console.log(error))

        API.get(`/v1/appointment/?appointment_status=1&collaborador=${decoded.user_id}&is_active=true`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                setCards(res.data.data.filter(cita => moment(cita.date_appointment).toDate().getTime() > moment(new Date()).subtract(1, 'day').toDate().getTime()))
            })

    }

    useEffect(() => {
        getItems()
    }, [])

    const removeCard = (id, status) => {
        API.put(`/v1/appointmnets/${id}/`, {
            appointment_status: status
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        }).then((v) => {
            console.log("SUCCESS", v)
            getItems()
        }).catch(e => {
            console.log("ERROR", e)
            getItems()
            /* if (status == 5) {
                setMsgSnack('No se ha podido aceptar la cita - Para mas información revisar el correo electrónico de su cuenta')
                setOpenSnack(true)
            } */
        })
    }

    return (
        <div className={classes.root}>
            <SnackBar
                openSnack={openSnack}
                setOpenSnack={setOpenSnack}
            >
                {msgSnack}
            </SnackBar>
            <section style={{ marginBottom: '10px' }}>
                <Container>
                    <Typography className={classes.TitleSolicitud} variant='h4'>Citas</Typography>
                    <div className={classes.cardContainer}>
                        {cards.length > 0 ?
                            cards.map((card, idx) => (
                                <CardSolicitud comision={comision} card={card} idx={idx} id={card.id} removeCard={removeCard} />
                            ))
                            :
                            <h3 style={{ width: '100%', textAlign: 'center', fontFamily: 'Montserrat' }}>No hay solicitudes por el momento</h3>
                        }
                    </div>
                </Container>
            </section>
        </div>
    )
}
