import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  TextareaAutosize,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import JwtDecode from "jwt-decode";
import API from "../../API";
import ButtonCustom from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import LinearLoader from "../../UI/FullScreenLoader/FullScreenLoader";
import Camera from "../../Assets/camera.svg";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Components/cropImage";
import ListServices from "./ListServices";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  card: {
    position: "relative",
    width: "320px",
    height: "320px",
    background: "#ccc",
    margin: "2rem",
  },
  btnDeleteImg: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    position: "absolute",
    top: "1rem",
    right: "1rem",
    border: "none",
    color: "white",
    backgroundColor: "#E62C4C",
    transition: "all 0.3s ease-out",
    "&:hover": {
      color: "#E62C4C",
      backgroundColor: "white",
      height: "28px",
      width: "28px",
    },
  },
  profileImg: {
    "&:hover": {
      backgroundColor: "black",
      opacity: ".9",
    },
  },
  camera: {
    opacity: "1",
    "&:hover": {
      opacity: "1",
    },
  },
}));

export default function Contenido() {
  const history = useHistory();
  const classes = useStyles();

  const [arrayImg, setArrayImg] = useState([]);
  const [imgProfileLoad, setImgProfileLoad] = useState("");
  const [imgProfile, setImgProfile] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  // const [idContent, setIdContent] = useState(false)
  const [arrayImgView, setarrayImgView] = useState([]);
  const [errorImg, setErrorImg] = useState(false);
  const [visible, setVisible] = useState(false);
  const [imageToRemove, setImageToRemove] = useState();
  const [loadingUI, setLoadingUI] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [isProfile, setIsProfile] = useState(true);
  const [zoom, setZoom] = useState(1);
  const decoded = JwtDecode(localStorage.getItem("access"));
  const getInfo = () => {
    const decoded = JwtDecode(localStorage.getItem("access"));
    API.get(`/user/${decoded.user_id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        const subscription = res.data.collaborator_suscription;
        setName(res.data.first_name);
        setLastName(res.data.last_name);
        setDescription(res.data.description);
        setImgProfile(res.data.photo);
        setarrayImgView(res.data.gallery);
        if (decoded.type === 4) return;
        if (subscription.length === 0)
          return history.push("/perfil/membresias");
        if (subscription.length > 0 && !subscription[0].is_paid)
          history.push("/perfil/membresias");
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getInfo();
  }, []);

  // useEffect(() => {
  //     const decoded = JwtDecode(localStorage.getItem('access'))

  //     API.get(`/available-services/?is_active=True&user__id=${decoded.user_id}`)
  //         .then(res => {
  //             res.data.data.forEach((el) => {
  //                 // setInputList([...inputList,{ id: el.id, servicio: el.name, precio: el.price, duracion: el.service_length, tipo: el.catService }])
  //                 inputList.push({ id: el.id, servicio: el.name, precio: el.price, duracion: el.service_length, tipo: el.catService })
  //             })

  //         })
  //         .catch(error => console.log(error))
  // }, [])

  const getImgByIdContent = (id) => {
    API.get(`/image/?content__id=${id}`)
      .then((res) => {
        // console.log(res.data.data)
        setarrayImgView(res.data.data);
        // arrayImgView.push(Object.values(res.data.data.url))
      })
      .catch((error) => console.log(error));
  };

  const addImgToArray = (img) => {
    if (img.size / 1024 / 1024 > 2) {
      return;
    }
    setIsProfile(false);
    setImgProfileLoad(URL.createObjectURL(img));
  };

  const addProfileImg = (img) => {
    //setLoadingUI(true)
    if (img.size / 1024 / 1024 > 2) {
      return;
    }
    setIsProfile(true);
    setImgProfileLoad(URL.createObjectURL(img));
    /*
        const data = new FormData()
        data.append('photo', img)
        data.append('is_active', true)


        const decoded = JwtDecode(localStorage.getItem('access'))

        API.put(`/users/${decoded.user_id}/`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                console.log(res)
                setLoadingUI(false)
                setImgProfile(URL.createObjectURL(img))
            })
            .catch(err => {
                console.log(err)
                setLoadingUI(false)
            })*/
  };

  const removeImgFromArray = (idx) => {
    arrayImgView.splice(idx, 1);
    setarrayImgView([...arrayImg]);
  };

  const createContentUser = () => {
    setLoadingUI(true);

    const decoded = JwtDecode(localStorage.getItem("access"));

    const dataUpdateContent = {
      first_name: name,
      last_name: lastName,
      description,
      is_active: true,
    };

    API.put(`/users/${decoded.user_id}/`, dataUpdateContent, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => setLoadingUI(false))
      .catch((error) => {
        setLoadingUI(false);
        console.log(error);
      });
  };

  const saveImages = () => {
    setLoadingUI(true);
    if (arrayImg.length > 0) {
      arrayImg.forEach((el, idx) => {
        if (el.id) {
          setLoadingUI(false);
          return console.log("actualizar");
        }

        const decoded = JwtDecode(localStorage.getItem("access"));

        let formData = new FormData();

        formData.append(`collaborator_id`, decoded.user_id);
        formData.append(`collaborator_gallery`, el.collaborator_gallery);

        API.post("/v1/collabotaro-gallery/", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
          .then((res) => {
            setLoadingUI(false);
            setArrayImg([]);
          })
          .catch((error) => {
            setLoadingUI(false);
          });
      });
    } else {
      setLoadingUI(false);
    }
    setTimeout(() => {
      getInfo();
    }, 3000);
  };

  const deleteImgSelected = () => {
    setLoadingUI(true);

    const { imgId, index } = imageToRemove;

    const imgs = [...arrayImgView];
    // const imgs = [...arrayImg]

    if (imgId) {
      return API.delete(`/v1/collabotaro-gallery/${imgId}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
        .then((res) => {
          imgs.splice(index, 1);
          setarrayImgView(imgs);
          setVisible(false);
          setLoadingUI(false);
        })
        .catch((err) => {
          setLoadingUI(false);
        });
    }
    setLoadingUI(false);

    imgs.splice(index, 1);
    setarrayImgView(imgs);
    setVisible(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = async () => {
    try {
      if (isProfile) {
        const croppedImage = await getCroppedImg(
          imgProfileLoad,
          croppedAreaPixels,
          0
        );

        const decoded = JwtDecode(localStorage.getItem("access"));
        setImgProfileLoad("");
        setImgProfile(croppedImage);
        setLoadingUI(true);

        let blob = await fetch(croppedImage).then((r) => r.blob());
        var file = new File([blob], "profileImg.jpeg");

        const data = new FormData();
        data.append("photo", file);
        data.append("is_active", true);
        API.put(`/users/${decoded.user_id}/`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
          .then((res) => {
            setLoadingUI(false);
            // setImgProfile(URL.createObjectURL(img))
          })
          .catch((err) => {
            setLoadingUI(false);
          });
        //  setCroppedImage(croppedImage)
      } else {
        const croppedImage = await getCroppedImg(
          imgProfileLoad,
          croppedAreaPixels,
          0
        );
        let urlImg;
        let blob = await fetch(croppedImage).then((result) => {
          // console.log(result)
          urlImg = result.url;
          return result.blob();
        });
        let file = new File([blob], "image.jpeg");
        setArrayImg([...arrayImg, { collaborator_gallery: file, url: urlImg }]);
        setarrayImgView([...arrayImgView, { collaborator_gallery: urlImg }]);
        setImgProfileLoad("");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveImage = (img, index) => {
    // if has an id it means that its come from db
    if (img.id) {
      setVisible(true);
      setImageToRemove({ imgId: img.id, index: index });
    } else {
      let filter = arrayImg.filter((element, index) => {
        return element.url !== img.collaborator_gallery;
      });

      let indexImgRemove2 = arrayImgView.indexOf(img);
      let filter2 = arrayImgView.filter((element, index) => {
        return index !== indexImgRemove2;
      });
      setArrayImg(filter);
      setarrayImgView(filter2);
    }
  };

  const cardUploadsImages = () => {
    let arrayHtml = [];
    let index = arrayImgView.length;
    for (index; index < 5; index++) {
      arrayHtml.push(
        <div className={classes.card} key={index}>
          <div
            className="image-upload"
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#FFE3D8",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <label htmlFor={`file-input-${index}`}>
              <img
                className={classes.camera}
                style={{ height: "80px" }}
                src={Camera}
                alt="ojito"
              />
            </label>
            <input
              type="file"
              id={`file-input-${index}`}
              style={{ display: "none" }}
              onChange={(e) => addImgToArray(e.target.files[0])}
            />
          </div>
        </div>
      );
    }

    return arrayHtml;
  };

  return (
    <div>
      <LinearLoader visible={loadingUI} />

      <Modal visible={errorImg} onClose={() => setErrorImg(false)}>
        <div className={classes.modal}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <h2>La imagen debe pesar menos de 2 mb</h2>
            <div style={{ width: "200px" }}>
              <ButtonCustom onClick={() => setErrorImg(false)}>
                Aceptar
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal>
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <div className={classes.modal}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <h2>¿Seguro que desea eliminar esta imagen?</h2>
            <div style={{ width: "200px" }}>
              <ButtonCustom onClick={deleteImgSelected}>Aceptar</ButtonCustom>
            </div>
          </div>
        </div>
      </Modal>
      <Container style={{ marginTop: "3%", marginBottom: "3%" }}>
        <Typography
          style={{ fontFamily: "Montserrat", color: "#A52F43" }}
          variant="h4"
        >
          Crear portafolio
        </Typography>
        <Typography
          style={{ marginTop: "40px", fontFamily: "Montserrat" }}
          variant="h6"
        >
          Agrega tu mejor foto de perfil, tu nombre y una breve descripción de
          tus servicios.
        </Typography>
        <Grid
          container
          style={{
            marginTop: "30px",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <Grid
            item
            sm={6}
            sx={12}
            className={classes.profileImg}
            style={{
              background: "#FFE3D8",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: "url(" + imgProfile + ")",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="image-upload">
              <label htmlFor="file-input">
                <img
                  className={classes.camera}
                  style={{ height: "80px" }}
                  src={Camera}
                  alt="ojito"
                />
              </label>

              <input
                onChange={(e) => addProfileImg(e.target.files[0])}
                id="file-input"
                style={{ display: "none" }}
                type="file"
              />
            </div>
            {/* <p>(imagenes permitidas: PNG*, JPG*)</p> */}
          </Grid>
          <Grid
            item
            sm={6}
            sx={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <form style={{ marginLeft: "15px" }}>
              <Typography variant="h6" style={{ fontFamily: "Montserrat" }}>
                Nombre(s)
              </Typography>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  backgroundColor: "#FFE3D8",
                  marginTop: 10,
                  width: "100%",
                  fontFamily: "Montserrat !important",
                }}
                id="outlined-basic2"
                variant="outlined"
              />
              <Typography variant="h6" style={{ fontFamily: "Montserrat" }}>
                Apellidos
              </Typography>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                style={{
                  backgroundColor: "#FFE3D8",
                  marginTop: 10,
                  width: "100%",
                  fontFamily: "Montserrat !important",
                }}
                id="outlined-basic2"
                variant="outlined"
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ marginTop: "40px", fontFamily: "Montserrat" }}
                  variant="h6"
                >
                  Descripción de servicios
                </Typography>
                <Typography style={{ marginTop: "40px" }} variant="caption">
                  (100 caracteres)
                </Typography>
              </div>
              {decoded.type === 4 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="caption">
                    NOTA: los links siempre seran remplazados por la palabra
                    "aquí!".
                  </Typography>

                  <Typography variant="caption">
                    Ejemplo: "
                    <strong>ve nuestros servicios, http://example.com</strong>",
                    sera remplazado por: "
                    <strong>ve nuestros servicios, aquí!</strong>"
                  </Typography>
                </div>
              )}

              <TextareaAutosize
                style={{
                  marginTop: 10,
                  padding: 8,
                  width: "100%",
                  backgroundColor: "#FFE3D8",
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                }}
                rows={20}
                maxLength="100"
                aria-label="maximum height"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </form>
          </Grid>

          <ButtonCustom
            onClick={createContentUser}
            style={{ marginTop: "40px", width: "30%" }}
          >
            GUARDAR
          </ButtonCustom>
        </Grid>
        <section style={{ marginTop: "5%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ fontFamily: "Montserrat", color: "#A52F43" }}
              variant="h4"
            >
              Galería de fotos
            </Typography>
            <Typography
              style={{
                marginBottom: "40px",
                fontFamily: "Montserrat",
                marginTop: "40px",
              }}
              variant="h6"
            >
              Agrega hasta 5 fotos sobre tu trabajo para que los clientes puedan
              ver tu talento.
            </Typography>
          </div>
        </section>

        <section>
          <div className={classes.container}>
            {arrayImgView.length > 0 &&
              arrayImgView.map((el, i) => (
                <div className={classes.card} key={i}>
                  <button
                    onClick={(e) => handleRemoveImage(el, i)}
                    className={classes.btnDeleteImg}
                  >
                    X
                  </button>
                  <img
                    key={i}
                    src={el.collaborator_gallery}
                    style={{ height: "100%", width: "100%" }}
                    alt={el.url}
                  />
                </div>
              ))}
            {cardUploadsImages()}
          </div>
          {arrayImg.length > 0 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonCustom
                type="submit"
                onClick={saveImages}
                style={{ marginTop: "40px", width: "30%" }}
              >
                GUARDAR
              </ButtonCustom>
            </div>
          )}
        </section>

        <ListServices />
      </Container>
      <Modal
        visible={imgProfileLoad ? true : false}
        onClose={() => setImgProfileLoad("")}
      >
        <div className={classes.modal}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <Cropper
              image={imgProfileLoad}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              minZoom={0.5}
              maxZoom={3}
              cropSize={{ width: 200, height: 200 }}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div style={{ width: "200px", marginTop: 300 }}>
              <ButtonCustom onClick={showCroppedImage}>Aceptar</ButtonCustom>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
