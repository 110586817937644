import React, { useState } from 'react'
import './Login.css'
import { Container, Typography, Grid, makeStyles, TextField, Button } from '@material-ui/core'
import API from '../../API';
import { useHistory } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import Logo from '../../Assets/hg_logo_large.svg'
import SnackBar from '../../UI/SnackBar/SnackBar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
    },
    grid: {
        display: 'flex',
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center'
    },
    center: {
        alignSelf: 'center',
        backgroundColor: 'white',
        display: 'flex',
        width: '100vw',
        height: '400px'
    },
    centeredContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    },
    logo: {
        height: '4rem',
    },
    btnLogin: {
        marginTop: '20px',
        backgroundColor: '#A52F43',
        width: '100%',
        color: 'white',
        fontFamily: 'Montserrat',
        "&:hover, &:focus": {
            marginTop: '20px',
            backgroundColor: '#A52F43',
            width: '100%',
            color: 'white',
            fontFamily: 'Montserrat',
        },
    }

}));

export default function Login() {

    const classes = useStyles();

    const history = useHistory()


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [resetPassword, setResetPassword] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [msgSnack, setMsgSnack] = useState('')
    const [success, setSuccess] = useState(false)


    function handleSubmit(e) {
        e.preventDefault()
        API.post('/token/', { email: email, password: password, type: 2 })
            .then(res => {
                console.log(res)
                localStorage.setItem('isAuth', 'true')
                localStorage.setItem('access', res.data.access)
                localStorage.setItem('refresh', res.data.access)
                history.go(0)
            })
            .catch(err => {
                console.log(err)
                setMsgSnack(err.response.data.errors)
                setOpenSnack(true)
            })
    }
    function handleReset() {
        API.post('/recover-password/', { email: email })
            .then(res => {
                setSuccess(true)
                setTimeout(() => {
                    setResetPassword(false)
                    setSuccess(false)
                }, 5000);
            })
            .catch(err => {

            })
    }
    const login = () => (
        <Grid className={classes.centeredContainer} item xs={12} sm={6} style={{ alignSelf: 'center' }} >
            <Typography style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} variant='h3'>Iniciar Sesión</Typography>
            <Typography style={{ fontFamily: 'Montserrat', color: '#c2c2c2', fontWeight: 'bold' }} variant='h6'>Bienvenido</Typography>
            <form>
                <TextField onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', backgroundColor: 'white', marginTop: 10 }} id="outlined-basic2" label="Email" variant="outlined" />
                <TextField onChange={(e) => setPassword(e.target.value)} type='password' style={{ width: '100%', backgroundColor: 'white', marginTop: 10 }} id="outlined-basic" label="Contraseña" variant="outlined" />

                <Button onClick={(e) => handleSubmit(e)} className={classes.btnLogin}>Iniciar Sesión</Button>
                <div style={{ paddingTop: 10 }}>
                    <a style={{ cursor:"pointer" }} onClick={() => {
                        setResetPassword(true)
                    }}>
                        ¿Olvidaste tu contraseña?
                </a></div>
            </form>
        </Grid>
    )
    const reset = () => (
        <Grid className={classes.centeredContainer} item xs={12} sm={6} style={{ alignSelf: 'center' }} >
            <Typography style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} variant='h3'>Recuperar contraseña</Typography>
            <p style={{ color: '#bbb' }}>
                    {
                        success ? `Se ha enviado un correo electrónico a ${email}.` :
                            'Introduzca su correo electrónico y recibirá un mensaje con pasos para recuperar su cuenta. '
                    }
                </p>
            {!success &&<form>
                    <TextField onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', backgroundColor: 'white', marginTop: 10 }} id="outlined-basic2" label="Email" variant="outlined" />
                    <Button onClick={(e) => handleReset(e)} className={classes.btnLogin}>Enviar</Button>
                    <div style={{ paddingTop: 10 }}>
                        <a style={{ cursor:"pointer" }} onClick={() => {
                            setResetPassword(false)
                        }}>
                            Regresar
                </a></div>
                </form>}
        </Grid>
    )
    return (
        <>
            <SnackBar
                openSnack={openSnack}
                setOpenSnack={setOpenSnack}
            >
                {msgSnack}
            </SnackBar>
            <nav className='main'>
                <div className='mobileContent'>
                    <span
                        style={{ fontSize: '2em', cursor: 'pointer' }}>
                        <FaBars />
                    </span>
                    <img
                        src={Logo} alt="HolyGlamLogo"
                    />
                </div>
                <div className='content'>
                    <div
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={Logo} alt="HolyGlamLogo" />
                    </div>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </nav>
            <div className={classes.root}>
                <img
                    className={classes.logo}
                    src={Logo}
                />
                <div className={classes.center}>
                    <Container className={classes.container}>
                        <Grid className={classes.grid} container >
                            {!resetPassword ? login() : reset()}
                        </Grid>
                    </Container>
                </div>
            </div>
        </>
    )
}
