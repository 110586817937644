import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe("pk_live_vliaiQMiBGe8hlwwJw1rrklb00aoF1FL17")
//const stripePromise = loadStripe("pk_test_51GqNQ3LciIrnnArvk5Sk7hbW4Uc927x7HSM0XZZKNsZB5tpNbUaAWoFVtcNkFkLOrlqiw01b8MdaQEaXRfaApvN500ZYKpFi71")

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
