import React, { useEffect, useState, useRef } from "react";

import API from "../../API";
import jwtDecode from "jwt-decode";
import Button from "../../UI/Button/Button";
import classes from "../Perfil/MiPerfil.module.css";
import placeholder from "../../Assets/placeholder_img.svg";

export default function MiPerfil() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLasName] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [photo, setPhoto] = useState("");
  const [phone, setPhone] = useState("");
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const decoded = jwtDecode(localStorage.getItem("access"));

  useEffect(() => {
    API.get(`/user/${decoded.user_id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res.data.first_name);
        setEmail(res.data.email);
        setFirstName(res.data.first_name);
        setLasName(res.data.last_name);
        setCity(res.data.city);
        setPhoto(res.data.photo);
        setPhone(res.data.phone);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    API.get("/v1/cities/")
      .then((res) => {
        console.log(res.data.data);
        setCities(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  function handleEdit() {
    setLoading(true);
    const decoded = jwtDecode(localStorage.getItem("access"));
    API.put(
      `/users/${decoded.user_id}/`,
      {
        first_name: firstName,
        last_name: lastName,
        city: city,
        phone: phone,
        is_active: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  function activateUser() {
    const decoded = jwtDecode(localStorage.getItem("access"));
    setLoading(true);

    API.post(
      `/v1/stripe-payment-method/account_links/?collaborator_pk=${decoded.user_id}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    )
      .then((responseConnectaID) => {
        console.log("R", responseConnectaID);

        setLoading(false);
        window.open(responseConnectaID.data.url, "_blank");

        //      console.log(res)
        //    window.location.href = res.data.url
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  function openConnect() {
    API.post(`/v1/login_links/`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res);
        window.open(window.location.href, "_blank");
        window.location.href = res.data.url;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const inputImg = useRef(null);
  function handleImgEdit() {
    inputImg.current.click();
  }

  function saveImg(file) {
    console.log(file);
    setLoading(true);

    const data = new FormData();
    data.append("photo", file);
    data.append("is_active", true);

    const decoded = jwtDecode(localStorage.getItem("access"));
    API.put(`/users/${decoded.user_id}/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        console.log(res);
        setPhoto(URL.createObjectURL(file));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <>
      <div className={classes.main}>
        <div className={classes.photoContainer}>
          <img
            alt="profile"
            src={photo ? photo : placeholder}
            className={classes.photo}
          />
          <div onClick={handleImgEdit}>Reemplazar</div>
          {decoded.type === 2 && (
            <Button onClick={activateUser} disabled={loading}>
              STRIPE
            </Button>
          )}
          {/*<Button
                        onClick={openConnect}
                        disabled={loading}
                    >
                        STRIPE
                    </Button>*/}
          <input
            type="file"
            ref={inputImg}
            onChange={(e) => saveImg(e.target.files[0])}
            style={{ display: "none" }}
          />
        </div>
        <div style={{ marginTop: "0px" }} className={classes.field}>
          <label className={classes.label}>Nombre</label>
          {!editable ? null : (
            <>
              <input
                className={classes.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                className={classes.input}
                value={lastName}
                type="text"
                onChange={(e) => setLasName(e.target.value)}
              />
            </>
          )}
          <div className={classes.text} hidden={editable}>
            {firstName} {lastName}
          </div>
        </div>
        <div className={classes.field}>
          <label className={classes.label}>Correo</label>
          <div className={classes.text}>{email}</div>
        </div>
        <div className={classes.field}>
          <label className={classes.label}>Ciudad</label>
          <select
            value={city}
            onChange={(e) => setCity(e.target.value)}
            disabled={!editable}
          >
            {cities.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.field}>
          <label className={classes.label}>Teléfono</label>
          {!editable ? null : (
            <input
              className={classes.input}
              type="text"
              defaultValue={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          )}
          <div className={classes.text} hidden={editable}>
            {phone}
          </div>
        </div>
      </div>
      <Button
        onClick={editable ? handleEdit : () => setEditable(true)}
        disabled={loading}
      >
        {editable ? "GUARDAR" : "EDITAR"}
      </Button>
    </>
  );
}
