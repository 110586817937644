import React from 'react'
import { MdClose } from 'react-icons/md'

import classes from './Modal.module.css'

export default function Modal(props) {
    return (
        <>
            {
                props.visible ?
                <div className={classes.backdrop}>
                    <div className={classes.main}>
                        <div className={classes.closeBtn} onClick={props.onClose}>
                            <MdClose />
                        </div>
                        <div className={classes.content}>
                            {props.children}
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    )
}
