import React from 'react'
import './Button.css'

export default function Button(props) {
    return (
        <button
            onClick={props.onClick} 
            disabled={props.disabled}
            className='btn'
            style={props.style}
            >
            {props.children}
        </button>
    )
}
