import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import './App.css';
import Home from './views/Home';
import Terms from './views/Terms';
import Navbar from './UI/Navbar/Navbar';
import Footer from './UI/Footer/Footer';
import Login from './views/Login/Login';
import RecoveryPassword from './views/Login/RecoveryPassword';
import Membresia from './views/Membresia/Membresia';
import PrivateRoute from './PrivateRoute';
import Contenido from './views/Contenido/Contenido';
import Agenda from './views/Agenda/Agenda';
import Perfil from './views/Perfil/Perfil';
import Solicitud from './views/Perfil/Solicitud';
import Historial from './views/Perfil/Historial';
import Guardia from './views/Perfil/Guardia';
import MetodosPago from './views/Perfil/MetodosPago'

function App() {

  const currentUser = localStorage.getItem('isAuth') || sessionStorage.getItem('isAuth')

  return (
    <div className="App">
      {currentUser && <Navbar />}
      <PrivateRoute exact path="/" component={Home} />
      <Route exact path="/terminos-condiciones" render={() =>  (<Terms/>) } />
      <Route exact path="/login" render={() => currentUser ? (<Redirect to='/' />) : (<Login/>) } />
      <Route exact path="/reset-password/:id" render={(props) => currentUser ? (<Redirect to='/' />) : (<RecoveryPassword props={props}/>) } />
      <PrivateRoute exact path="/contenido" component={Contenido} />
      <PrivateRoute exact path="/membresia" component={Membresia} />
      <PrivateRoute exact path="/agenda" component={Agenda} />
      <PrivateRoute exact path="/perfil" component={Perfil} />
      <PrivateRoute exact path="/perfil/solicitud" component={Solicitud} />
      <PrivateRoute exact path="/perfil/historial" component={Historial} />
      <PrivateRoute exact path="/perfil/guardia" component={Guardia} />
      <PrivateRoute exact path="/perfil/MetodosPago" component={MetodosPago}/>
      <PrivateRoute exact path="/perfil/membresias" component={Membresia}/>
      <Footer/>
    </div>
  );
}

export default App;
