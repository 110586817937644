import { Snackbar, SnackbarContent } from '@material-ui/core'
import React, { useState } from 'react'

export default function SnackBar(props) {
    const { children } = props

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={props.openSnack}
            autoHideDuration={3000}
            onClose={() => props.setOpenSnack(false)}
        >
            <SnackbarContent
                style={{ backgroundColor: '#F08080', }}
                message={
                    <span style={{ fontWeight: '700' }}>
                        {children}
                    </span>
                }
            />
        </Snackbar>
    )
}
