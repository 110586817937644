import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import './CardSectionStyles.css'

import Loader from '../../UI/FullScreenLoader/FullScreenLoader'
import Modal from '../../UI/Modal/Modal'
import API from '../../API'
import Button from '../../UI/Button/Button'
import classes from './MetodosPago.module.css'
import ProfileSideBar from './ProfileSideBar'
import JwtDecode from 'jwt-decode'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function PaymentMethods({ location }) {

    const [cards, setCards] = useState([])
    const [cardHolder, setCardHolder] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackContent, setSnackContent] = useState('')
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const [clientSecret, setClientSecret] = useState('')
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        API.get(`/user/get_client_stripe/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                console.log(res.data)
                setClientSecret(res.data)
            })
    }, [])

    useEffect(() => {

        const decoded = JwtDecode(localStorage.getItem('access'))

        API.get(`/user/${decoded.user_id}/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                console.log(res.data)
                setEmail(res.data.email)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        const decoded = JwtDecode(localStorage.getItem('access'))

        API.get(`/v1/stripe-payment-method/?user=${decoded.user_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                if (res.data === 'No payment methods available') {
                    setCards([])
                    return
                }
                console.log(res.data)
                setCards(res.data.data)
            })
    }, [])

    function handleDelete(id) {
        console.log(id)
        API.delete(`/v1/stripe-payment-method/${id}/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                window.location.reload(false)
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: cardHolder,
                    email
                },
            }
        });

        if (result.error) {
            // Display result.error.message in your UI.
            setSnackContent(result.error.message)
            setOpenSnack(true)
            setLoading(false)
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer
            console.log(result.setupIntent.payment_method)
            API.post(`/v1/stripe-payment-method/add_payment_method/`, { payment: result.setupIntent.payment_method }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`
                }
            })
                .then(res => {
                    window.location.reload(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }

    }

    return (
        <ProfileSideBar location={location}>
            <Loader visible={loading} />
            <Modal
                visible={modalOpen}
                onClose={() => setModalOpen(false)}
                disabled={!cardHolder.length > 0}
            >
                <label>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                </label>
                <label>
                    <input
                        type="text" placeholder="Titular de la tarjeta"
                        style={{
                            marginTop: '2%', width: '100%', border: 'none',
                            boxShadow: '0 7px 14px 0 rgba(49,49,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.08)',
                            padding: '10px', borderRadius: '4px'
                        }}
                        onChange={(e) => setCardHolder(e.target.value)}
                    />
                </label>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button style={{ marginTop: '40px' }} onClick={handleSubmit}>AÑADIR</Button>
                </div>
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={() => setOpenSnack(false)}
            >
                <SnackbarContent
                    style={{ backgroundColor: '#F08080', }}
                    message={
                        <span style={{ fontWeight: '700' }}>
                            {snackContent}
                        </span>
                    }
                />
            </Snackbar>
            <div className={classes.main}>
                <p style={{ fontFamily: 'Montserrat', fontSize: '18px' }}>
                    Éstos son los métodos de pago de la membresía que hayas seleccionado para su pago mensual.
                    Recuerda mantener tus datos correctos y actualizados.
                </p>
                {
                    cards.length > 0 ?
                        cards.map((card, i) => (
                            <div className={classes.card} key={card.id}>
                                <span className={classes.title}>Tarjeta {i + 1}</span> <br />
                                <div className={classes.div}>
                                    <strong>Titular: {card.name_card}</strong>
                                </div>
                                <div className={classes.div}>
                                    <strong>Cuenta:</strong> **** **** **** {card.last4}
                                </div>
                                <div className={classes.div}>
                                    <strong>Vencimiento: {card.exp_month} / {card.exp_year}</strong>
                                </div>
                                <div
                                    onClick={() => {
                                        if (cards.length == 1) {
                                            setSnackContent("Debes tener al menos una tarjeta ")
                                            setOpenSnack(true)
                                            setLoading(false)
                                        }
                                        else
                                            handleDelete(card.id)
                                    }}
                                    className={classes.delete}>
                                    ELIMINAR
                        </div>
                            </div>
                        ))
                        : 'Sin métodos de pago'
                }
            </div>
            <div className={classes.main} style={{ justifyContent: 'center' }}>
                <Button onClick={() => setModalOpen(true)}>
                    AGREGAR NUEVO
            </Button>
            </div>
        </ProfileSideBar>
    )
}