import React, { useEffect, useState } from 'react'
import './Home.css'
import { Grid, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CardSolicitud from '../UI/CardSolicitud/CardSolicitud';
import API from '../API';
import JwtDecode from 'jwt-decode'

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '70vh'
        marginBottom: '18%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    grid: {
        display: 'flex',
        height: '100%',
        alignContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    card: {
        position: 'relative',
        width: '30%',
        height: '300px',
        backgroundColor: '#ccc',
        marginLeft: '40px'
    },
    imgHero: {
        position: 'absolute',
        marginLeft: '20px',
        right: '60px',
        height: '450px',
        bottom: '-15px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    welcomeText: {
        fontFamily: 'bb-book-mono',
        color: '#fff',
        fontWeight: 'bold',
        marginBottom: 45,
        letterSpacing: 3,
        [theme.breakpoints.only('xs')]: {
            fontSize: '30px'
        },
    },
    subtitleText: {
        fontFamily: 'bb-book-mono',
        color: '#fff',
        [theme.breakpoints.only('xs')]: {
            fontSize: '20px'
        },
    },
    TitleSolicitud: {
        fontFamily: 'Montserrat',
        color: '#A52F43',
        letterSpacing: 3,
        // marginLeft: '105px',
        marginTop: '3%',
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            fontSize: '25px'
        },
    },
    img: {
        transform: "translate(-8px, 30px)"
    }
}));

export default function Home() {

    const classes = useStyles();
    const [cards, setCards] = useState([])
    const [comision, setComision] = useState(0)

    const getItems = () =>{
        const decoded = JwtDecode(localStorage.getItem('access'))
        API.get(`/v1/membership/`)
        .then(res => {
            //console.log("DATA",res.data)
            API.get(`/user/${decoded.user_id}/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access')}`
                }
            })
                .then(res2 => {
                    if (res2.data.collaborator_suscription.length && res2.data.collaborator_suscription.length>0) {
                        setComision(res2.data.collaborator_suscription[0].membership.comision)
                    }
                })
        })
        .catch(error => console.log(error))
        API.get(`/v1/appointment/?appointment_status=4&collaborador=${decoded.user_id}&is_active=true`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        })
            .then(res => {
                console.log("RES",res.data.data)
                setCards(res.data.data)
                API.get(`/v1/appointment/?appointment_status=3&collaborador=${decoded.user_id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access')}`
                    }
                })
                    .then(res => {
                        console.log("RES2",res.data.data)
                        setCards((cards)=>[...cards,...res.data.data])
                    })
            })
            
    }
    useEffect(() => {
        getItems()

    }, [])

    const removeCard = (id, status) => {
        API.put(`/v1/appointmnets/${id}/`, {
            appointment_status: status
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`
            }
        }).then((v) => {
            getItems()
        }).catch(e => {
            console.log(e)
        })
    }

    return (
        <div className={classes.root}>
            <section style={{ marginBottom: '10px' }}>
                <Container>
                    <Typography className={classes.TitleSolicitud} variant='h4'>Historial</Typography>
                    <div className={classes.cardContainer}>
                        {cards.length > 0 ?
                            cards.map((card, idx) => (
                                <CardSolicitud comision={comision} card={card} idx={idx} id={card.id} removeCard={removeCard} />
                            ))
                            :
                            <h3 style={{ width: '100%', textAlign: 'center', fontFamily: 'Montserrat' }}>No hay historial por el momento</h3>
                        }
                    </div>
                </Container>
            </section>
        </div>
    )
}
