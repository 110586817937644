import React, { useEffect, useState } from 'react'
import './Home.css'
import { Grid, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CardSolicitud from '../UI/CardSolicitud/CardSolicitud';
import API from '../API';
import JwtDecode from 'jwt-decode'
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    root: {
        // height: '70vh'
        marginBottom: '18%'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    grid: {
        display: 'flex',
        height: '100%',
        alignContent: 'center',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    card: {
        position: 'relative',
        width: '30%',
        height: '300px',
        backgroundColor: '#ccc',
        marginLeft: '40px'
    },
    imgHero: {
        position: 'absolute',
        marginLeft: '20px',
        right: '60px',
        height: '450px',
        bottom: '-15px',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    welcomeText: {
        fontFamily: 'bb-book-mono',
        color: '#fff',
        fontWeight: 'bold',
        marginBottom: 45,
        letterSpacing: 3,
        [theme.breakpoints.only('xs')]: {
            fontSize: '30px'
        },
    },
    subtitleText: {
        fontFamily: 'bb-book-mono',
        color: '#fff',
        [theme.breakpoints.only('xs')]: {
            fontSize: '20px'
        },
    },
    TitleSolicitud: {
        fontFamily: 'Montserrat',
        color: '#A52F43',
        letterSpacing: 3,
        // marginLeft: '105px',
        marginTop: '3%',
        marginBottom: 30,
        [theme.breakpoints.only('xs')]: {
            fontSize: '25px'
        },
    },
    img: {
        transform: "translate(-8px, 30px)"
    }
}));

export default function Home() {

    const classes = useStyles();
    const [cards, setCards] = useState([])
    const [comision, setComision] = useState(0)
 
    return (
        <div className={classes.root}>
            <section style={{ marginBottom: '10px' }}>
              <div style={{padding:20}}>
<p>
<h3 style={{textAlign:"center"}}>COLABORADORES</h3>
POLÍTICAS PARA COLABORADORES
<br/>A. Para ser un colaborador, es indispensable enviar una solicitud al correo info@holyglam.com.mx, asistir a la entrevista y llevar todos los papeles solicitados por Holy Glam
<br/>B. Una vez aceptado, se le enviará una clave temporal para poder crear su perfil y portafo- lio dentro de la plataforma de Holy Glam.
<h3>MEMBRESÍAS</h3>
<br/>A. Es necesario contratar una membresía para poder ser parte de Holy Glam.
<br/>B. Solo se permiten 2 cambios de membresía anuales a partir de su ingreso
<br/>C. Solo se acepta el pago de la membresía por tarjeta de crédito. No se aceptan pagos en efectivo, transferencias o cheques
<br/>D. Las membresías son de costo mensual. El incumplimiento de cualquiera de los pagos mensuales bloqueará de manera inmediata el acceso al perfil de los colaboradores.
<br/>E. Holy Glam realiza un cargo de comisión por cada servicio concluido del colaborador. Esta comisión varía dependiendo de la membresía que el colaborador seleccione
<br/>F. En caso de querer cancelar su membresía favor de comunicarse a oficina@holy- glam.com.mx
<br/>G. Holy Glam ofrece tres membresías:
     
<br/>H) Ninguna de las membresías cuenta con un número específico para las publicaciones en redes y newsletters, pudiendo ser mensuales o bimensuales dependiendo del número de colaboradores que hay que publicar.
<br/>I) Los colaboradores deberán de proporcionar contenido propio y cooperarán con Holy Glam, cuando se les requiera, para crear los contenidos y de esta manera ser publicados.
<br/>J) Es indispensable que dentro de la plataforma usen fotos profesionales para su perfil. No se permiten fotos de baja calidad, tomadas con el celular. Holy Glam deberá aprobar cada perfil antes de darlo de alta dentro de la plataforma.
<br/>K) Holy Glam puede en cualquier momento y sin previo aviso suspender o bloquear cual- quier perfil y se le notificará al colaborador la razón por medio de un correo electrónico a efec- tos de que el colaborador pueda subsanar el error o incumplimiento.
<h3>PERFIL</h3>
<br/>A. Dentro de su perfil podrá ofrecer únicamente 5 servicios a la vez.
<br/>B. El perfil deberá contener una descripción de su trabajo, fotos de portafolio mismo contenido que deberá cumplir con los lineamientos establecidos por Holy Glam.
<br/>C. Los precios por servicio son establecidos por el colaborador.
<br/>D. El pago se realizará de acuerdo a las condiciones establecidas en el apartado denomi- nado PAGOS del presente documento. El colaborador tendrá que ingresar a su perfil, entrar a su historial y marcar el servicio como concluido para que se le bonifique el pago correspondi- ente. El pago será bonificado a través de la plataforma Stripe a la tarjeta bancaria que se haya registrado previamente.
<br/>E. El colaborador será el único responsable por sus horarios de trabajo pudiendo determi- nar su disponibilidad dentro del calendario de HolyGlam utilizando el apartado de “Dis- ponible”. El colaborador deberá verificar sus horarios periódicamente a efectos de mantener- los actualizados.
<br/>F. Una vez que el perfil esté dado de alta dentro de la plataforma de Holy Glam podrá em- pezar a recibir notificaciones de servicios. No se darán de alta perfiles incompletos.
<br/>G. Holy Glam se reserva el derecho de bloqueo o suspensión de cualquier perfil, sin previo aviso, siempre que a juicio de Holy Glam dicho perfil este en incumplimiento con cualquiera de las políticas, reglas de uso o código de ética de Holy Glam.
<h3>CITAS</h3>
<br/>A. Le llegará un correo al colaborador notificando que tiene una orden de servicio. El colaborador tiene que entrar a su perfil y aceptar el servicio para que sea confirmado y se marque en el calendario como ocupado. Si dentro de 24 horas el servicio no fue aceptado, será cancelado.
<br/>B. Un día antes del servicio le llegará un correo al colaborador recordando los detalles del servicio
<br/>H. Holy Glam no se hace responsable por correos electrónicos no vistos. Le sugerimos revisar siempre su historial de citas dentro de su perfil para cualquier cancelación que se requiera dentro de los márgenes de tiempo aceptados en el entendido de que tendrá 24 horas para realizar cualquier aceptación o cancelación de citas.
 
<br/>I. El cliente tiene la obligación de darle al colaborador un lugar de trabajo seguro, limpio y libre de cualquier peligro. De lo contrario el colaborador puede cancelar el servicio si sintiese que su vida corre peligro. Para este caso contactar a info@holyglam.com.mx donde se abrirá un expediente para analizar el caso en caso de requerir un reembolso.
<br/>J. Si el día del servicio el colaborador no encuentra el domicilio escrito en la orden de compra, Holy Glam hará 3 intentos por localizar al cliente. Si después del tercer intento el cliente no responde o la dirección es incorrecta la cita será cancelada sin reembolso por el servicio.
<br/>K. El colaborador no tiene la obligación de cumplir con el servicio después de 10 minutos de retraso por no poder localizar al cliente.
<br/>L. No está permitido el consumo de bebidas alcohólicas, drogas o cualquier sustancia ilícita durante el servicio.
<br/>M. Holy Glam actúa como mediador antes del servicio, durante y posterior al servicio. Esto es para protección tanto del cliente como del colaborador por lo que no se permite comuni- cación entre las dos partes sin que Holy Glam esté enterado. De lo contrario Holy Glam no se hace responsable de las garantías del servicio y/o eventos desafortunados que pudiesen haber.
<br/>N. El colaborador podrá usar el botón de modalidad Express únicamente si no tiene servi- cios agendados para este día. La modalidad Express solo tiene duración de 24 horas.
<br/>O. El colaborador podrá usar el botón de modalidad Express únicamente si no tiene servi- cios agendados para este día. La modalidad Express solo tiene duración de 24 horas. El horario Express inicia a las 6 a.m. y concluye a las 10 p.m. después de ese horario.
<br/>P. Los servicios en modalidad Express tiene un costo del 10% extra a los servicios agenda- dos de manera regular.
<br/>Q. Los servicios agendados dentro la modalidad Express no tienen cambios, reembolsos o cancelaciones.
<br/>R. Cualquier colaborador puede usar la modalidad Express, siempre y cuando no tenga citas agendadas para ese día.
<br/>S. Los servicios en modalidad Express deberán ser confirmados dentro del plazo de una hora a partir de su solicitud de lo contrario se rechazarán de manera automática.
<h3>PAGOS</h3>
<br/>Los colaboradores recibirán los pagos de los servicios debidamente proporcionados de manera semanal en la modalidad de semana atrasada, el día martes de cada semana. Al término de cada semana Holy Glam realizará una relación de cada servicio prestado y com- pletado por los colaboradores y verificará que no exista alguna pena o deuda por incum- plimiento de acuerdo al apartado denominado PENAS del presente documento a efectos de realizar una compensación entre los mismos, el resultado de dicha compensación será el pago a realizar por Holy Glam a los colaboradores. Dichos pagos serán depositados a las cuen- tas proporcionadas por los colaboradores en su apartado de perfil.
<br/>El colaborador será el único responsable la cuenta de institución bancaria que proporcione, misma que deberá contener todos los datos necesarios a efectos que Holy Glam pueda realizar los depósitos mencionados. En caso de existir algún retraso por error de los datos pro- porcionados Holy Glam no será responsable por los daños que dichos retrasos cause al colab- orador.
  
  <h3>PENAS</h3>
  <br/>Los colaboradores que incurran en cualquiera de los siguientes actos tendrán penas monetar- ias mismas que en este acto consienten a que les sean descontados de los pagos pendientes por cobrar por servicios otorgados:
  <br/>A.- No llegar a una cita confirmada por parte del colaborador.
  <br/>B.- Proporcionar un servicio distinto al solicitado por el cliente final
  <br/>C.- Realizar actos que vayan en contra de la moral y buenas prácticas de Holy Glam ya se durante un servicio, dentro de las instalaciones de Holy Glam o en plataformas digitales
  <br/>D.- Llegar a realizar un servicio con aliento alcohólico o bajo de las influencias de cualquier estupefaciente
  <br/>E.- No observar las medidas preventivas e higiénicas que acuerden las autoridades compe- tentes y la que indiquen Holy Glam para la seguridad y protección de los Colaboradores y clientes finales.
  <br/>F.- Si el cliente realiza una reclamación en contra del Colaborador por mal servicio, retraso, o cualquier incumplimiento.
  <br/>En caso de cualquier incumplimiento por parte del Colaborador, Holy Glam generará un expediente de investigación en determinará si procede la sanción, dicho periodo de investigación no podrá exceder de un plazo de tres meses. Las penas serán por el 15% (quince por ciento) del ticket de compra del servicio del cual se generó cualquiera de los incumplimientos descritos en presente apartado de PENAS.
<h3>REVOCACION O SUSPENSIÓN DE MEMBRESIA</h3>
<br/>Holy Glam podrá suspender o revocar de manera indefinida las membresías a los colabora- dores que incurran en cualquiera de los siguientes supuestos:
<br/>1. No hagan uso correcto de la plataforma.
<br/>2. No colabore con Holy Glam en la creación de contenido para la plataforma en más de una ocasión.
<br/>3. Colaboradores que declinen más de 3 citas al mes serán suspendidos un mes.
<br/>4. Colaboradores que incurran en dos o más supuestos del apartado de penas en un periodo de treinta días será suspendido.
<br/>5. Colaboradores que sean suspendidos más de dos veces pueden ser revocados de su membresía de manera permanente.
<br/>6. Para cualquier aclaración escribir a info@holyglam.com.mx
  
</p>
              </div>
            </section>
        </div>
    )
}
