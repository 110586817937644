import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  makeStyles,
  Button,
} from "@material-ui/core";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "./calendar.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import Modal from "../../UI/Modal/Modal";
import API from "../../API";
import { useEffect } from "react";
import JwtDecode from "jwt-decode";
import SnackBar from "../../UI/SnackBar/SnackBar";
import { useHistory } from "react-router-dom";
require("moment/locale/es.js");
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  card: {
    position: "relative",
    width: "30%",
    height: "400px",
    backgroundColor: "#FFEBE6",
    marginLeft: "40px",
    marginBottom: "30px",
    borderRadius: "25px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  btnAceptar: {
    width: "60%",
    backgroundColor: "#A52F43",
    color: "white",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    letterSpacing: "2px",
    marginTop: "15px",
  },
  btnDeclinar: {
    width: "60%",
    marginTop: 15,
    color: "#A52F43",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    borderWidth: "1.7px",
    borderColor: "#A52F43",
  },
  li: {
    fontFamily: "Montserrat",
  },
}));

export default function Agenda() {
  let allViews = Object.keys(Views).map((k) => Views[k]);
  // .filter(view => view !== 'day' && view !== 'week' )
  const history = useHistory();
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [handleModal, setHandleModal] = useState(false);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [titleStatus, setTitleStatus] = useState("No disponible");
  const [specificDay, setSpecificDay] = useState("");
  const [currentView, setCurrentView] = useState("month");
  const [dateSelect, setDateSelect] = useState(new Date());
  const [numWeeks, setNumWeeks] = useState(1);
  const [isGuard, setIsGuard] = useState(false);
  const [firstDate, setFirstDate] = useState(undefined);
  const [lastDate, setLastDate] = useState(undefined);
  const [hours, setHours] = useState(undefined);
  const [idSchedule, setIdSchedule] = useState(undefined);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState("");

  //states to Checks
  const [repeatOneDay, setRepeatOneDay] = useState(true);
  const [repeatForAWeek, setRepeatForAWeek] = useState(false);
  const [repeatForTwoWeeks, setRepeatForTwoWeeks] = useState(false);
  const [repeatForAMonth, setRepeatForAMonth] = useState(false);
  const [checkRepeatSelected, setCheckRepeatSelected] = useState(null);
  //end states to Checks

  const decoded = JwtDecode(localStorage.getItem("access"));
  const getUserData = () => {
    API.get(`/user/${decoded.user_id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    }).then((res2) => {
      const subscription = res2.data.collaborator_suscription;
      if(decoded.type===4) history.push("/perfil");
      if (subscription.length === 0) history.push("/perfil/membresias");
      if (subscription.length > 0 && !subscription[0].is_paid)
        history.push("/perfil/membresias");
    });
  };

  useEffect(() => {
    getUserData();

    getCalendarItems();
  }, [firstDate, lastDate]);

  useEffect(() => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    API.get(
      `/v1/appointment/?appointment_status=5&collaborador=${decoded.user_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    )
      .then((res) => {
        const eventsConverted = res.data.data.map((el, idx) => {
          return {
            title: el.event.length && el.event[0].title,
            start: el.event.length && new Date(el.event[0].start),
            end: el.event.length && new Date(el.event[0].end),
            type: el.appointment_status,
          };
        });

        setEvents((events) => [...events, ...eventsConverted]);
      })
      .catch((error) => console.log(error));
  }, [firstDate, lastDate]);

  const handleSelect = ({ start, end }) => {
    if (currentView !== "month" && currentView !== "") {
      setHandleModal(true);

      let startTime = moment(start);
      let endTime = moment(end);

      setDateStart(moment(start).format());
      setDateEnd(moment(end).format());

      let duration = moment.duration(endTime.diff(startTime));
      let hours = duration.asHours();
      setHours(hours);
    } else {
      setCurrentView("day");
      setDateSelect(start);
    }
  };

  const getCalendarItems = () => {
    let firstDateFormated = "";
    let lastDateFormated = "";

    const decoded = JwtDecode(localStorage.getItem("access"));

    if (!firstDate && !lastDate) {
      const date = new Date();
      const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      firstDateFormated = moment(firstDate).format("YYYY-MM-DD");
      setFirstDate(firstDateFormated);

      const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      lastDateFormated = moment(lastDate).format("YYYY-MM-DD");
      setLastDate(lastDateFormated);
    }

    API.get(
      `/v1/schedule/`,
      {
        params: {
          start: firstDate && lastDate ? firstDate : firstDateFormated,
          end: firstDate && lastDate ? lastDate : lastDateFormated,
          perpage: 500,
          is_active: true,
          collaborator_id: decoded.user_id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    )
      .then((res) => {
        const eventsConverted = res.data.data.map((el, idx) => {
          return {
            id: el.id,
            title: el.occupied === true ? "Hora ocupada" : "Hora disponible",
            start: new Date(el.start),
            end: new Date(el.end),
            occupied: el.occupied,
            type: el.appointment_add,
          };
        });

        setEvents(eventsConverted);

        if (firstDate && lastDate) {
          const list = document.getElementsByClassName("rbc-date-cell");
          eventsConverted
            .filter((x) => x.occupied)
            .map((el) => {
              for (var i = 0; i < list.length; ++i) {
                let insert = false;
                var item = list[i]; // Calling myNodeList.item(i) isn’t necessary in JavaScript
                var tag = document.createElement("div");
                tag.style.backgroundColor = "green";
                tag.style.float = "right";
                tag.style.width = "10px";
                tag.style.height = "10px";
                tag.style.marginTop = "4px";
                tag.style.marginRight = "4px";
                tag.style.borderRadius = "50%";
                let childs = item.childNodes;
                if (childs.length <= 1)
                  for (var a = 0; a < childs.length; ++a) {
                    let child = childs[a];
                    Number(child.textContent);
                    if (
                      Number(new Date(el.start).getDate()) ==
                      Number(child.textContent)
                    ) {
                      insert = true;
                      child.style.float = "right";
                    }
                  }
                if (insert) item.appendChild(tag);
              }
            });
        }
      })
      .catch((error) => console.log(error));
  };

  function sendData() {
    let data = getDataShedule();

    API.post(`/v1/schedule/`, getFormData(data), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        setHandleModal(false);

        const eventsConverted = res.data.data.map((el, idx) => {
          return {
            id: el.id,
            title: el.occupied === true ? "Hora ocupada" : "Hora disponible",
            start: new Date(el.start),
            end: new Date(el.end),
            occupied: el.occupied,
            type: el.appointment_add,
          };
        });

        setCheckRepeatOneDay();
        getCalendarItems();
      })
      .catch((err) => {
        setHandleModal(false);
        console.log(err);
        if (err.response) {
          console.log(err.response);
        }
      });
  }

  /*
        //<<< Parámetros requeridos a enviar en todas las opciones de repeticiones("1 día", "1 Semana", "2 Semanas", "1 Mes")
        collaborator_id: 'id',          //usuario logueado
        start: '2021-04-01T06:00:00',   //fecha y hora de inicio de horario
        hours: 1,                       //Cantidad de horas que trabajará (Siempre se manda)
        //>>>> Parámetros requeridos a enviar en todas las posibles opciones de repeticiones("1 día", "1 Semana", "2 Semanas", "1 Mes")
        
        //<<< Parámetros requeridos cuando el horario seleccionado es para "1 día".
        is_specific_day: true,          //TRUE: El horario es solo por ese dia.
        guard: true,                    //Genera el horario de 1 día en el horario seleccionado seleccionado
        //>>>> Fin Parámetros requeridos cuando el horario seleccionado es para "1 día".

        //<<< Parámetros requeridos cuando el horario seleccionado es "Repetir 1 semana".
        weeks: 1,                       //Repite el horario seleccionado por 1, 2, 3 o 4 semanas en días hábiles (L-V)
        //>>>> Fin Parámetros requeridos cuando el horario seleccionado es "Repetir 1 semana".

        //<<< Parámetros requeridos cuando el horario seleccionado es "Repetir 2 semanas".
        weeks: 2,                       //Repite el horario seleccionado por 1, 2, 3 o 4 semanas en días hábiles (L-V)
        //>>>> Fin Parámetros requeridos cuando el horario seleccionado es "Repetir 2 semanas".

        //<<< Parámetros requeridos cuando el horario seleccionado es "Repetir 1 Mes".
        weeks: 4,                       //Repite el horario seleccionado por 1, 2, 3 o 4 semanas en días hábiles (L-V)
        //>>>> Fin Parámetros requeridos cuando el horario seleccionado es "Repetir 1 Mes".


        is_every_week: true,            //Repite exactamente el mismo horario seleccionado por 4 semanas
        Ejemplo: 
            Seleccionamos "Martes 1 Agosto del 2021 de 8AM-9AM" y se repetirá los días:
                "Martes 1 Agosto del 2021 de 8AM-9AM"
                "Martes 10 Agosto del 2021 de 8AM-9AM"
                "Martes 17 Agosto del 2021 de 8AM-9AM"
                "Martes 24 Agosto del 2021 de 8AM-9AM"
    */
  const getDataShedule = () => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    const data = {
      collaborator_id: decoded.user_id,
      start: dateStart.toString().slice(0, -6),
      hours: hours,
    };

    if (repeatForAWeek) {
      data["weeks"] = 1;
      return data;
    }

    if (repeatForTwoWeeks) {
      data["weeks"] = 2;
      return data;
    }

    if (repeatForAMonth) {
      data["weeks"] = 4;
      return data;
    }

    data["guard"] = true;
    data["is_specific_day"] = true;

    return data;
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  }

  const removeSchedule = () => {
    const date = moment(event.start).format("YYYY-MM-DDTHH:mm:ss-06:00");

    API.delete(`/v1/schedule/`, {
      data: {
        schedules: [date],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        const filtered = events.filter((v) => v.id !== event.id);
        setEvents([...filtered]);
        setMsgSnack(res.data.response);
        setOpenSnack(true);
      })
      .catch((err) => {
        console.log(err.response);
        setMsgSnack(err.response.data.errors);
        setOpenSnack(true);
      });

    setVisible(false);
    setEvent(null);
  };

  function removeModal(event) {
    setEvent(event);
    setVisible(true);
  }

  const styleItemAgenda = (event, start, end, isSelected) => {
    let asignBgColor = event.type?.length
      ? fnColorBg(event.type[0].type_appointment) + "b5"
      : "#A52F43b5";
    let color = event.occupied ? "black" : "#fff";

    if (event.title.includes("disponible")) {
      color = "black";
      asignBgColor = "white";
    }

    let style = {
      backgroundColor: asignBgColor,
      borderRadius: "0px",
      opacity: 1,
      color: color,
      border: "0px",
      display: "flex",
      height: "500px !important",
    };

    return {
      style,
    };
  };

  function fnColorBg(type) {
    if (type === 1) {
      return "#EF8F8E";
    }

    if (type === 2) {
      return "#FF952D";
    }

    return "#A52F43";
  }

  const onCheckRepeatForTwoWeeks = (e) => {
    if (e.target.checked) {
      setRepeatForTwoWeeks(true);

      setRepeatOneDay(false);
      setRepeatForAWeek(false);
      setRepeatForAMonth(false);

      setCheckRepeatSelected(e.target.name);
    } else {
      setCheckRepeatOneDay();
    }
  };

  const onCheckRepeatForAMonth = (e) => {
    if (e.target.checked) {
      setRepeatForAMonth(true);

      setRepeatOneDay(false);
      setRepeatForAWeek(false);
      setRepeatForTwoWeeks(false);

      setCheckRepeatSelected(e.target.name);
    } else {
      setCheckRepeatOneDay();
    }
  };

  const onCheckRepeatForAWeek = (e) => {
    if (e.target.checked) {
      setRepeatForAWeek(true);

      setRepeatOneDay(false);
      setRepeatForTwoWeeks(false);
      setRepeatForAMonth(false);

      setCheckRepeatSelected(e.target.name);
    } else {
      setCheckRepeatOneDay();
    }
  };

  const setCheckRepeatOneDay = () => {
    setRepeatOneDay(true);
    setRepeatForAWeek(false);
    setRepeatForTwoWeeks(false);
    setRepeatForAMonth(false);

    setCheckRepeatSelected(null);
  };

  const isDisabledCheck = (checkboxName) => {
    if (checkRepeatSelected) {
      return checkRepeatSelected !== checkboxName;
    }

    return false;
  };

  return (
    <React.Fragment>
      <SnackBar openSnack={openSnack} setOpenSnack={setOpenSnack}>
        {msgSnack}
      </SnackBar>
      <Container style={{ marginTop: "3%" }}>
        <Modal visible={visible} onClose={() => setVisible(false)}>
          <div className={classes.modal}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            >
              <h2>¿Seguro que desea eliminar este horario?</h2>
              <div style={{ width: "200px", display: "flex" }}>
                <Button
                  onClick={removeSchedule}
                  className={classes.btnAceptar}
                  variant="contained"
                  disableElevation
                >
                  ACEPTAR
                </Button>
                <div style={{ width: 20 }} />
                <Button
                  onClick={() => {
                    setVisible(false);
                  }}
                  className={classes.btnDeclinar}
                  variant="contained"
                  disableElevation
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          visible={handleModal}
          onClose={() => setHandleModal(false)}
          disableAccept
        >
          <Container>
            <Typography variant="h4" style={{ fontFamily: "bb-book-mono" }}>
              Administrar
            </Typography>
            <Grid
              container
              style={{
                marginTop: "30px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sm={8}
                sx={6}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#E62C4C",
                      fontWeight: "bold",
                    }}
                    variant="caption"
                  >
                    Estatus de Horario:{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={4} sx={6}>
                <FormControl style={{ minWidth: 120 }} variant="outlined">
                  <Select
                    required
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) => setTitleStatus(e.target.value)}
                    value={titleStatus}
                  >
                    <MenuItem value={"No disponible"}>Disponible</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "30px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sm={8}
                sx={6}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#E62C4C",
                      fontWeight: "bold",
                    }}
                    variant="caption"
                  >
                    Fecha:{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={4} sx={6}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "#E62C4C",
                    fontWeight: "bold",
                  }}
                  variant="caption"
                >
                  {moment(dateStart).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "30px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sm={8}
                sx={6}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#E62C4C",
                      fontWeight: "bold",
                    }}
                    variant="caption"
                  >
                    Inicio:{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={4} sx={6}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "#E62C4C",
                    fontWeight: "bold",
                  }}
                  variant="caption"
                >
                  {moment(dateStart).format("LT-a")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "30px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sm={8}
                sx={6}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      color: "#E62C4C",
                      fontWeight: "bold",
                    }}
                    variant="caption"
                  >
                    Fin:{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item sm={4} sx={6}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    color: "#E62C4C",
                    fontWeight: "bold",
                  }}
                  variant="caption"
                >
                  {moment(dateEnd).format("LT-a")}
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                name="checkRepeatForTwoWeeks"
                style={{ paddingLeft: "0px" }}
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={onCheckRepeatForTwoWeeks}
                disabled={isDisabledCheck("checkRepeatForTwoWeeks")}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  color: "#E62C4C",
                  fontWeight: "bold",
                }}
              >
                Repetir durante 2 semanas
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                name="checkRepeatForAMonth"
                style={{ paddingLeft: "0px" }}
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={onCheckRepeatForAMonth}
                disabled={isDisabledCheck("checkRepeatForAMonth")}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  color: "#E62C4C",
                  fontWeight: "bold",
                }}
              >
                Repetir durante 1 mes
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Checkbox
                name="checkRepeatForAWeek"
                style={{ paddingLeft: "0px" }}
                inputProps={{ "aria-label": "primary checkbox" }}
                onChange={onCheckRepeatForAWeek}
                disabled={isDisabledCheck("checkRepeatForAWeek")}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  color: "#E62C4C",
                  fontWeight: "bold",
                }}
              >
                Repetir toda la semana
              </Typography>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={sendData}
                className={classes.btnAceptar}
                variant="contained"
                disableElevation
              >
                ACEPTAR
              </Button>
            </div>
          </Container>
        </Modal>
        <Typography
          style={{ fontFamily: "Montserrat", color: "#A52F43" }}
          variant="h4"
        >
          Agenda
        </Typography>
        <Typography
          style={{ marginTop: "40px", fontFamily: "Montserrat" }}
          variant="h5"
        >
          Para crear un horario disponible, haz click sobre la fecha que quieras
          modificar o dirígete al calendario de día/semana y selecciona el rango
          de hora a laborar y ¡Listo!
        </Typography>

        <div style={{ height: "600px", marginTop: "40px", marginBottom: 30 }}>
          <Calendar
            selectable
            step={60}
            view={currentView}
            min={new Date(0, 0, 0, 6, 0, 0)}
            max={new Date(0, 0, 0, 22, 0, 0)}
            allDayAccessor={false}
            onSelectEvent={(event) => removeModal(event)}
            eventPropGetter={styleItemAgenda}
            onSelectSlot={handleSelect}
            showMultiDayTimes
            views={allViews}
            date={dateSelect}
            onView={(e) => {
              setTimeout(() => {
                const list = document.getElementsByClassName("rbc-date-cell");
                events
                  .filter((x) => x.occupied)
                  .map((el) => {
                    for (var i = 0; i < list.length; ++i) {
                      let insert = false;
                      var item = list[i]; // Calling myNodeList.item(i) isn’t necessary in JavaScript
                      var tag = document.createElement("div");
                      tag.style.backgroundColor = "green";
                      tag.style.float = "right";
                      tag.style.width = "10px";
                      tag.style.height = "10px";
                      tag.style.marginTop = "4px";
                      tag.style.marginRight = "4px";
                      tag.style.borderRadius = "50%";
                      let childs = item.childNodes;
                      if (childs.length <= 1)
                        for (var a = 0; a < childs.length; ++a) {
                          let child = childs[a];
                          Number(child.textContent);
                          if (
                            Number(new Date(el.start).getDate()) ==
                            Number(child.textContent)
                          ) {
                            insert = true;
                            child.style.float = "right";
                          }
                        }
                      if (insert) item.appendChild(tag);
                    }
                  });
              }, 1000);

              setCurrentView(e);
            }}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            events={events}
            culture="es"
            onNavigate={(date) => {
              setDateSelect(date);
              const firstDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                1
              );
              const firstDateFormated = moment(firstDate).format("YYYY-MM-DD");
              console.log(firstDateFormated);
              setFirstDate(firstDateFormated);

              const lastDate = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
              );
              const lastDateFormated = moment(lastDate).format("YYYY-MM-DD");
              console.log(lastDateFormated);
              setLastDate(lastDateFormated);
            }}
            messages={{
              next: <GrNext />,
              previous: <GrPrevious />,
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
              work_week: "Entre semana",
            }}
          />
        </div>
      </Container>
    </React.Fragment>
  );
}
