import React, { useEffect, useState } from "react";
import ProfileSideBar from "./ProfileSideBar";
import { Typography, Container, makeStyles } from "@material-ui/core";
import JwtDecode from "jwt-decode";
import API from "../../API";
import moment from "moment";
import Button from "../../UI/Button/Button";
import SnackBar from "../../UI/SnackBar/SnackBar";

const useStyles = makeStyles((theme) => ({
  grid2: {
    display: "flex",
    position: "relative",
    backgroundColor: "#FFD9CB",
    alignContent: "center",
    marginLeft: "30px",
    marginTop: "20px",
    height: "300px",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      marginLeft: 0,
    },
  },
  titleOption: {
    fontFamily: "bb-book-mono",
    color: "#E62C4C",
    fontWeight: "bold",
    marginTop: "10%",
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px",
    },
  },
  imgCard: {
    position: "relative",
    height: "350px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  titleCard: {
    fontFamily: "bb-book-mono",
    color: "#E62C4C",
    fontWeight: "bold",
    lineHeight: "31px",
    letterSpacing: "1.28px",
  },
  btnAceptar: {
    width: "300px",
    backgroundColor: "#A52F43",
    color: "white",
    textAlign: "center",
    fontFamily: "bb-book-mono",
    fontWeight: "bold",
    letterSpacing: "2px",
  },
  textCenter: {
    textAlign: "center",
    color: "#A52F43",
  },
}));

export default function Guardia({ location }) {
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState("");
  const classes = useStyles();
  const [activo, setActivo] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [arrayEvents, setArrayEvents] = useState([]);
  const [arrayAppointments, setArrayAppointments] = useState([]);


  function makeGuard() {
    const decoded = JwtDecode(localStorage.getItem("access"));
    const date = new Date();
    const firstDate = new Date(new Date().setDate(new Date().getDate()));
    const firstDateFormated = moment(firstDate).format("YYYY-MM-DD");

    const lastDate = new Date();
    const lastDateFormated = moment(lastDate).format("YYYY-MM-DD");

    if (activo) {
      let continueDisable = true;
      arrayEvents.map((event) => {
        if (event.occupied) {
          continueDisable = false;
        }
      });
      if (continueDisable) {
        arrayEvents.map((event) => {
          API.delete(`/v1/schedule/`, {
            data: {
              schedules: [event.start],
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          });
        });
        setActivo(false);
      } else {
        setOpenSnack(true);
        setMsgSnack(
          "Agenda ocupada - no es posible desactivar el modo express"
        );
      }
    } else {
      if (arrayAppointments?.length == 0) {
        const data = {
          collaborator_id: decoded.user_id,
          start: firstDateFormated + `T06:00:00`,
          guard: true,
          hours: 16,
        };

        console.log(data);

        API.post(`/v1/schedule/`, getFormData(data), {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
          .then((res) => {
            console.log("DATA", res);
            setActivo(true);

            // window.location.reload(false)
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {
              console.log(err.response);
            }
          });
      } else {
        setOpenSnack(true);
        setMsgSnack("Agenda ocupada - no es posible activar el modo express");
      }
    }
  }

  useEffect(() => {
    try {
      const time = moment().format("HH");
      console.log("TIME", time);
      if (Number(time) < 6 || Number(time) > 21) {
        setHideButton(true);
      }
    } catch (ex) {
      console.log(ex);
    }
    const decoded = JwtDecode(localStorage.getItem("access"));

    const date = new Date();
    const firstDate = new Date(new Date().setDate(new Date().getDate()));
    const firstDateFormated = moment(firstDate).format("YYYY-MM-DD");

    const lastDate = new Date(new Date().setDate(new Date().getDate() + 1));
    const lastDateFormated = moment(lastDate).format("YYYY-MM-DD");

    API.get(
      `/v1/appointment/?appointment_status=5&collaborador=${
        decoded.user_id
      }&date_appointment=${moment().format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    )
      .then((res) => {
        setArrayAppointments(res.data.data);
      })
      .catch((error) => console.log(error));

    API.get(
      `/v1/schedule/`,
      {
        params: {
          start: firstDateFormated,
          end: lastDateFormated,
          perpage: 500,
          is_active: true,
          collaborator_id: decoded.user_id,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    )
      .then((res) => {
        const eventsConverted = res.data.data.map((el, idx) => {
          return {
            ...el,
            id: el.id,
            title: el.occupied === true ? "Hora ocupada" : "Hora disponible",
            start: new Date(el.start),
            end: new Date(el.end),
            occupied: el.occupied,
            type: el.appointment_add,
          };
        });
        console.log(eventsConverted);

        const filter = eventsConverted.filter((events) => {
          const hour = moment(events.start).format("HH");
          console.log("HOUR", hour);
          if (hour == "06" || hour == "21") {
            return events;
          }
        });
        if (filter.length == 2) {
          console.log("FILTER", filter);
          setActivo(true);
          setArrayEvents(eventsConverted);
        }
      })
      .catch((error) => console.log(error));
  }, []);



  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  }

  return (
    <ProfileSideBar location={location}>
      <SnackBar openSnack={openSnack} setOpenSnack={setOpenSnack}>
        {msgSnack}
      </SnackBar>
      <Container>
        <Typography
          style={{
            fontFamily: "Montserrat",
            color: "#A52F43",
            fontWeight: "bold",
            paddingTop: "5%",
          }}
          variant="h5"
        >
          Condiciones para dar servicios EXPRESS
        </Typography>
        <p style={{ fontFamily: "Montserrat", fontSize: "18px" }}>
          Para poder dar servicios en la modalidad EXPRESS es únicamente para
          los días que no se tengan citas agendadas. La modalidad EXPRESS puede
          ser de gran utilidad para los días que estés libre y quieras
          aprovechar la oportunidad para que nuevos clientes conozcan tu
          trabajo, además de que ganas el 10% extra por tus servicios!
          <br />
          <br />
          Es muy importante que cuando estés en EXPRESS seas muy atento a las
          solicitudes que puedas tener ese día porque los tiempos para aceptar
          las solicitudes son mucho más cortos que los normales y recuerda que
          es muy probable que los clientes que soliciten servicios en esta
          modalidad necesiten rapidez. ¡Que tu buen trabajo hable por sí solo!
          ¡Mucho éxito!
          <br />
          <br />
          Los horarios en modalidad EXPRESS son de 6:00 am a 10:00 pm al
          concluir el día se desactiva de tu perfil tu disponibilidad.
        </p>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "8%" }}
        >
          {!hideButton && (
            <Button onClick={makeGuard}>
              {activo ? "DESACTIVAR CITA EXPRESS" : "ACTIVAR CITA EXPRESS"}
            </Button>
          )}
        </div>
      </Container>
    </ProfileSideBar>
  );
}
