import React, { useEffect } from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CardMembresia from "./CardMembresia";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import API from "../../API";
import { useState } from "react";
import ProfileSideBar from "../Perfil/ProfileSideBar";
import JwtDecode from "jwt-decode";

//const stripePromise = loadStripe("pk_live_vliaiQMiBGe8hlwwJw1rrklb00aoF1FL17")
const stripePromise = loadStripe(
  "pk_test_51GqNQ3LciIrnnArvk5Sk7hbW4Uc927x7HSM0XZZKNsZB5tpNbUaAWoFVtcNkFkLOrlqiw01b8MdaQEaXRfaApvN500ZYKpFi71"
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "80vh",
    backgroundColor: "#E62C4C",
  },
  container: {
    display: "flex",
  },
  container2: {
    marginBottom: "5%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  grid: {
    display: "flex",
    alignContent: "center",
  },
  grid2: {
    display: "flex",
    backgroundColor: "#FFD5AA",
    alignContent: "center",
    marginLeft: "30px",
    marginTop: "20px",
    height: "300px",
    padding: "20px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  card: {
    position: "relative",
    width: "30%",
    height: "300px",
    backgroundColor: "#ccc",
    marginLeft: "40px",
  },
  imgHero: {
    position: "absolute",
    marginLeft: "20px",
    right: "60px",
    height: "450px",
    bottom: "-15px",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  titleMembresia: {
    fontFamily: "bb-book-mono",
    color: "#fff",
    fontWeight: "bold",
    marginBottom: 45,
    letterSpacing: 3,
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px",
    },
  },

  titleOption: {
    fontFamily: "bb-book-mono",
    color: "#E62C4C",
    fontWeight: "bold",
    marginTop: "10%",
    [theme.breakpoints.only("xs")]: {
      fontSize: "30px",
    },
  },
}));

export default function Membresia({ location }) {
  const [titulo, setTitulo] = useState("");
  const classes = useStyles();

  const [memembresias, setMemembresias] = useState([]);

  useEffect(() => {
    API.get(`/v1/membership/`)
      .then((res) => {
        console.log(res.data);
        setMemembresias(res.data.data);
        console.log(memembresias);
      })
      .catch((error) => console.log(error));
  }, []);

  const decoded = JwtDecode(localStorage.getItem("access"));
  console.log(decoded);

  const getUserData = () => {
    API.get(`/user/${decoded.user_id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    }).then((res2) => {
      const subscription = res2.data.collaborator_suscription;

      if (subscription.length === 0)
        return setTitulo(
          "Debe de poseer una membresía activa para utilizar la plataforma de colaboradores de Holy Glam"
        );
      if (subscription.length > 0 && !subscription[0].is_paid)
        return setTitulo(
          "Ha ocurrido un problema al procesar el cobro mensual de su membresía, revise su correo electrónico o contacte a un administrador de Holy Glam (info@holyglam.com.mx)"
        );
      setTitulo("Membresías");
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <ProfileSideBar location={location}>
      <Elements stripe={stripePromise}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: 500, textAlign: "center" }}>
            <h2>{titulo}</h2>
          </div>
          <Container className={classes.container2}>
            {memembresias.length &&
              memembresias.map((mem, idx) => (
                <React.Fragment key={idx}>
                  {/* <Typography className={classes.titleOption} variant='h5'>{mem.name}</Typography> */}
                  <CardMembresia idx={idx} memembresia={mem} />
                </React.Fragment>
              ))}
          </Container>
        </div>
      </Elements>
    </ProfileSideBar>
  );
}
