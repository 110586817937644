import React, { useState } from 'react'
import './Login.css'
import { Container, Typography, Grid, makeStyles, TextField, Button } from '@material-ui/core'
import API from '../../API';
import { useHistory } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import Logo from '../../Assets/hg_logo_large.svg'
import SnackBar from '../../UI/SnackBar/SnackBar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
    },
    grid: {
        display: 'flex',
        height: '100%',
        alignContent: 'center',
        justifyContent: 'center'
    },
    center: {
        alignSelf: 'center',
        backgroundColor: 'white',
        display: 'flex',
        width: '100vw',
        height: '400px'
    },
    centeredContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    },
    logo: {
        height: '4rem',
    },
    btnLogin: {
        marginTop: '20px',
        backgroundColor: '#A52F43',
        width: '100%',
        color: 'white',
        fontFamily: 'Montserrat',
        "&:hover, &:focus": {
            marginTop: '20px',
            backgroundColor: '#A52F43',
            width: '100%',
            color: 'white',
            fontFamily: 'Montserrat',
        },
    }

}));

export default function Login({ props }) {

    const classes = useStyles();

    const history = useHistory()

    console.log(props.match.params.id)
    const [paswordConfirm, setPasswordConfirm] = useState('')
    const [password, setPassword] = useState('')
    const [openSnack, setOpenSnack] = useState(false)
    const [msgSnack, setMsgSnack] = useState('')
    const [success, setSuccess] = useState(false)


    function handleSubmit(e) {
        e.preventDefault()
        API.post(`/reset-password/${props.match.params.id}`, {code:props.match.params.id,password: password,password_confirm:paswordConfirm})
        .then(res => {
            console.log(res)
            setSuccess(true)
            setTimeout(() => {
                history.push('/login') 
    
            }, 4000)
        })
        .catch(err => {
            console.log("err",err)
        })
      
    }


    const reset = () => (
        <Grid className={classes.centeredContainer} item xs={12} sm={6} style={{ alignSelf: 'center' }} >
            <Typography style={{ fontFamily: 'Montserrat', color: 'black', fontWeight: 'bold' }} variant='h3'>Recuperar contraseña</Typography>
            <p style={{ color: '#bbb' }}>
                {
                    success ? `Se ha restablecido su contraseña` :
                        'Ingresa tu nueva contraseña'
                }
            </p>
            {!success && <form>
                <TextField onChange={(e) => setPassword(e.target.value)} type='password' style={{ width: '100%', backgroundColor: 'white', marginTop: 10 }} id="outlined-basic" label="Contraseña" variant="outlined" />
                <TextField onChange={(e) => setPasswordConfirm(e.target.value)} type='password' style={{ width: '100%', backgroundColor: 'white', marginTop: 10 }} id="outlined-basic" label="Confirmar ontraseña" variant="outlined" />
                <Button onClick={(e) => handleSubmit(e)} className={classes.btnLogin}>Enviar</Button>
               
            </form>}
        </Grid>
    )
    return (
        <>
            <SnackBar
                openSnack={openSnack}
                setOpenSnack={setOpenSnack}
            >
                {msgSnack}
            </SnackBar>
            <nav className='main'>
                <div className='mobileContent'>
                    <span
                        style={{ fontSize: '2em', cursor: 'pointer' }}>
                        <FaBars />
                    </span>
                    <img
                        src={Logo} alt="HolyGlamLogo"
                    />
                </div>
                <div className='content'>
                    <div
                        style={{ cursor: 'pointer' }}
                    >
                        <img src={Logo} alt="HolyGlamLogo" />
                    </div>
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </nav>
            <div className={classes.root}>
                <img
                    className={classes.logo}
                    src={Logo}
                />
                <div className={classes.center}>
                    <Container className={classes.container}>
                        <Grid className={classes.grid} container >
                            {reset()}
                        </Grid>
                    </Container>
                </div>
            </div>
        </>
    )
}
