import React, { useState, useEffect } from "react";
import API from "../../API";
import JwtDecode from "jwt-decode";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import ButtonCustom from "../../UI/Button/Button";
import LinearLoader from "../../UI/FullScreenLoader/FullScreenLoader";

const ListServices = () => {
  const [loadingUI, setLoadingUI] = useState(false);
  const [services, setServices] = useState([]);
  const [catService, setCatService] = useState([]);

  const getServiceList = () => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    API.get(`/v1/collaborator-content/`, {
      params: {
        collaborator_id: decoded.user_id,
        is_active: true,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    })
      .then((res) => {
        let inputs = [];
        res.data.data.forEach((el) => {
          inputs.push({
            id: el.id,
            servicio: el.service_name,
            precio: el.service_price,
            duracion: el.duration,
            tipo: el.type,
          });
        });
        setServices(inputs);
      })
      .catch((error) => console.log(error));
  };

  const saveOrUpdate = async () => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };

    for (let service of services) {
      try {
        let data = {
          service_name: service.servicio,
          type: service.tipo,
          duration: service.duracion,
          collaborator_id: decoded.user_id,
          service_price: service.precio,
        };

        let url = "/v1/collaborator-content/";
        if (service.id) {
          url += `${service.id}/`;
          await API.put(url, data, options);
        } else {
          data["user"] = decoded.user_id;
          await API.post(url, data, options);
        }
      } catch (e) {
        throw e;
      }
    }

    return true;
  };

  const saveServices = async (e) => {
    setLoadingUI(true);
    e.preventDefault();
    if (services.length > 0) {
      try {
        await saveOrUpdate();
      } catch (e) {
        console.error(e);
      }
    }

    setLoadingUI(false);
    getServiceList();
  };

  const handleAddService = () => {
    setServices([
      ...services,
      { id: "", servicio: "", precio: "", duracion: "", tipo: "" },
    ]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...services];
    list[index][name] = value;
    setServices(list);
  };

  const deleteInputService = (idx, id) => {
    const decoded = JwtDecode(localStorage.getItem("access"));

    if (id) {
      API.delete(`/v1/collaborator-content/${id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
        .then((res) => {
          const list = [...services];
          list.splice(idx, 1);
          setServices(list);
        })
        .catch((error) => console.log(error));
    } else {
      const list = [...services];
      list.splice(idx, 1);
      setServices(list);
    }
  };

  useEffect(() => {
    getServiceList();
  }, []);

  useEffect(() => {
    API.get(`/v1/service-collaborator/`)
      .then((res) => {
        setCatService(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <section
      style={{ marginTop: "5%", border: "solid #E62C4C", padding: "20px" }}
    >
      <LinearLoader visible={loadingUI} />

      <form onSubmit={saveServices}>
        <Typography
          style={{ fontFamily: "Montserrat", color: "#A52F43" }}
          variant="h4"
        >
          Servicios
        </Typography>
        <Typography
          style={{
            marginBottom: "40px",
            marginTop: "40px",
            fontFamily: "Montserrat",
          }}
          variant="h6"
        >
          Agrega hasta 10 servicios para ofrecer. Ponle un nombre, el precio, la
          duración únicamente del servicio (no tienes que considerar el tiempo
          de tu traslado) y la categoría a la que pertenece el servicio y
          ¡listo!.
        </Typography>
        {services.length >= 0 &&
          services.map((el, i) => (
            <Grid
              key={i}
              container
              style={{
                marginTop: "30px",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sm={4}
                sx={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ marginRight: "30px" }}>
                  <Typography
                    style={{
                      marginBottom: "10px",
                      marginTop: "5px",
                      fontFamily: "Montserrat",
                    }}
                    variant="h6"
                  >
                    Servicio
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <div
                      onClick={() => deleteInputService(i, el.id)}
                      style={{
                        backgroundColor: "#E62C4C",
                        color: "#fff",
                        borderRadius: "50px",
                        height: "25px",
                        width: "20px",
                        alignSelf: "center",
                        alignItems: "center",
                        marginRight: "10px",
                        justifyContent: "center",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      X
                    </div>
                    <TextField
                      required
                      placeholder="Corte de cabello"
                      value={el.servicio}
                      onChange={(e) => handleInputChange(e, i)}
                      name="servicio"
                      style={{
                        backgroundColor: "#FFE3D8",
                        marginTop: 10,
                        width: "100%",
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                sm={4}
                sx={12}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ marginRight: "30px" }}>
                  <Typography
                    style={{
                      marginBottom: "10px",
                      marginTop: "5px",
                      fontFamily: "Montserrat",
                    }}
                    variant="h6"
                  >
                    Precio
                  </Typography>
                  <TextField
                    required
                    placeholder="45"
                    type="number"
                    value={el.precio}
                    onChange={(e) => handleInputChange(e, i)}
                    name="precio"
                    style={{
                      backgroundColor: "#FFE3D8",
                      marginTop: 10,
                      width: "100%",
                    }}
                    variant="outlined"
                  />
                </div>
              </Grid>
              <Grid
                item
                sm={2}
                sx={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{
                    marginBottom: "10px",
                    marginTop: "5px",
                    fontFamily: "Montserrat",
                  }}
                  variant="h6"
                >
                  Duración
                </Typography>

                <FormControl style={{ minWidth: 120 }} variant="outlined">
                  {/* <InputLabel id="demo-simple-select-outlined-label">30 Minutos</InputLabel> */}

                  <Select
                    placeholder="30 Minutos"
                    required
                    name="duracion"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) => handleInputChange(e, i)}
                    value={el.duracion}
                  >
                    <MenuItem value={60}>60 Minutos</MenuItem>
                    <MenuItem value={120}>120 Minutos</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                sm={2}
                sx={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ marginLeft: "30px" }}>
                  <Typography
                    style={{
                      marginBottom: "10px",
                      marginTop: "5px",
                      fontFamily: "Montserrat",
                    }}
                    variant="h6"
                  >
                    Tipo
                  </Typography>

                  <FormControl style={{ minWidth: "100%" }} variant="outlined">
                    {/* <InputLabel id="demo-simple-select-outlined-label-2">Peinado</InputLabel> */}
                    <Select
                      required
                      name="tipo"
                      labelId="demo-simple-select-outlined-label-2"
                      id="demo-simple-select-outlined-2"
                      onChange={(e) => handleInputChange(e, i)}
                      value={el.tipo}
                    >
                      {catService.length > 0 &&
                        catService.map((service, idx) => (
                          <MenuItem key={idx} value={service.id}>
                            {service.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          ))}
        {services.length <= 9 && (
          <Button
            onClick={handleAddService}
            style={{
              height: "50px",
              marginTop: "20px",
              width: "100%",
              border: "dashed",
            }}
            variant="outlined"
            color="secondary"
          >
            + Añadir servicio
          </Button>
        )}
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <ButtonCustom
            type="submit"
            style={{ marginTop: "40px", width: "30%" }}
          >
            GUARDAR
          </ButtonCustom>
        </div>
      </form>
    </section>
  );
};

export default ListServices;
