import React from 'react'
import Logo from '../../Assets/hg_logo_large.svg'
import { FaInstagram, FaFacebook } from 'react-icons/fa'
import './Footer.css'

export default function Footer() {
    return (
        <footer className='footerContainer'>
            <div>
            <a style={{ color: 'white' }} href='https://drive.google.com/file/d/19a9BjB5D8ZrpWkiK3flDukiA65DHCk9Q/view?usp=drive_link' target='_blank'>
                    Términos y condiciones
                </a>
                <br/>
                <a style={{ color: 'white' }} href='https://drive.google.com/file/d/1D90QOGPJEYn91z10uAzUuaH3SQIHVBy5/view?ts=60b936ef' target='_blank'>
                    Aviso de privacidad
                </a>
            </div>
            <div>
                <span onClick={() => {
                    window.open('https://www.facebook.com/holy.glam.mx', '_blank').focus();
                }} className='icon'><FaFacebook /></span> &nbsp;
                <span
                    onClick={() => {
                        window.open('https://instagram.com/_holyglam?igshid=1jv25wyapqu7s', '_blank').focus();
                    }}
                    className='icon'><FaInstagram /></span> <br />
                © HolyGlam 2021. Todos los derechos reservados
            </div>
            <div>
                <img src={Logo} alt="HolyGlam" />
            </div>
        </footer>
    )
}
